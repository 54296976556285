import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

class Logout extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    onLogout: PropTypes.func.isRequired
  };

  componentWillMount() {
    this.props.onLogout();
  }

  render() {
    const { isFetching, isLoggedIn } = this.props;

    return (
      <div className="Logout">
        {isLoggedIn ? <div>Logging out</div> : <Redirect to="/" />}
        {isFetching && (
          <Dimmer active>
            <Loader size="massive">Logging out</Loader>
          </Dimmer>
        )}
      </div>
    );
  }
}

export default Logout;
