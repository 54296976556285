import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Button, Grid, Header, Message } from "semantic-ui-react";

class Confirm extends Component {
  componentWillMount() {
    const { uid, token, sendConfirm } = this.props;
    sendConfirm(uid, token);
  }

  render() {
    const { success, apiError, messages, uid } = this.props;
    return (
      <Grid container textAlign="center" verticalAlign="middle">
        <Grid.Row style={{ height: "80vh" }}>
          <Grid.Column mobile={16} tablet={12} computer={8}>
            {success ? (
              <React.Fragment>
                <Header as="h2" color="teal" textAlign="center">
                  {messages.get("Your email address is verified.")}
                </Header>
                {messages.get("You can now")}
                <br />
                <Link to="/login">
                  <Button
                    color="teal"
                    size="large"
                    style={{ marginTop: "1em" }}
                  >
                    {messages.get("log in")}
                  </Button>
                </Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Header as="h2" color="teal" textAlign="center">
                  {messages.get("The verification failed. Make sure to use the latest email link.")}
                </Header>

                <Message info>
                  <Message.Header>
                    {messages.get("Your email might already be verified and you can now")}
                  </Message.Header>
                  <Link to="/login">
                    <Button
                      color="teal"
                      size="large"
                      style={{ marginTop: "1em" }}
                    >
                      {messages.get("log in")}
                    </Button>
                  </Link>
                </Message>

                <Message warning>
                  <Message.Header>{apiError}</Message.Header>
                  {messages.get("Need a")} <Link to={`/verified?uid=${uid}`}> {messages.get("new verification email?")} </Link>.
                </Message>
              </React.Fragment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Confirm.propTypes = {
  success: PropTypes.bool,
  apiError: PropTypes.string,
  sendConfirm: PropTypes.func.isRequired,
  uid: PropTypes.string,
  token: PropTypes.string
};

export default Confirm;
