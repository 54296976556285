import { connect } from "react-redux";

import { requestVerificationEmail } from '../Signup/SignupDuck';
import Verified from "./Verified";
import { getMessages } from "../../helpers/helpers";
import { parseQuery } from '../../helpers/helpers';

const mapStateToProps = (state, {location}) => {
  const query = location ? parseQuery(location.search) : {};
  const uid = query["uid"];
  return {
    messages: getMessages(state),
    apiError: state.signup.apiError,
    uid,
    isFetching: state.signup.isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: id => dispatch(requestVerificationEmail(id))
});

const VerifiedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Verified);
export default VerifiedContainer;
