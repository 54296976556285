import React, { Component } from "react";
import PropTypes from "prop-types";
import { Divider, Form, Button, Grid, Header, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";

class PasswordReset extends Component {
  componentWillUnmount(){
    this.props.resetData()
  }

  handlePasswordChange = e => this.props.setPassword(e.target.value);

  doSubmit = () =>
    this.props.handleSubmit(this.props.newPassword, this.props.access_token);

  render() {
    const { success, apiError, messages, isFetching, newPassword } = this.props;
    return (
      <Grid container textAlign="center" verticalAlign="middle">
        <Grid.Row style={{ height: "80vh" }}>
          <Grid.Column mobile={16} tablet={12} computer={8}>
            {apiError && typeof apiError === "string" && 
                <Message error>{apiError}</Message>
            }
            {apiError && typeof apiError === "object" && (
              <React.Fragment>
                {Object.values(apiError).map(err => (
                  <Message key={err} error>
                    {err}
                  </Message>
                ))}
              </React.Fragment>
            )}

            {success && (
              <Message info>
                <Message.Header>
                  {messages.get("Password changed successfully!")}
                </Message.Header>
              </Message>
            )}
            <Header as="h2" color="teal" textAlign="center">
              {messages.get("Enter a new password")}
            </Header>
            <Form
              size="large"
              onSubmit={this.doSubmit}
              error={!!apiError}
              loading={isFetching}
            >
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                name="newPassword"
                type="password"
                error={!!apiError && !!apiError.newPassword}
                value={newPassword}
                onChange={this.handlePasswordChange}
              />
              <Button color="teal" fluid size="large" disabled={isFetching}>
                {messages.get("Send")}
              </Button>
            </Form>

            <Divider />

            <Link to="/login">
              <Button fluid>{messages.get("Login")}</Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

PasswordReset.propTypes = {
  success: PropTypes.bool,
  apiError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isFetching: PropTypes.bool,
  newPassword: PropTypes.string,
  messages: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired
};

export default PasswordReset;
