/* eslint-disable */ 

module.exports = {
  de: {
    "Email must not be empty.": function(d) { return "E-Mail darf nicht fehlen."; },
    "Invalid Token": function(d) { return "Ungültiges Token"; },
    "This email is already verified.": function(d) { return "Diese E-Mail-Adresse ist schon bestätigt."; },
    "Email is already taken.": function(d) { return "E-Mail-Adresse ist schon in Benutzung."; },
    "An error occurred internally.": function(d) { return "Ein interner Fehler ist aufgetreten."; },
    "Password must not be empty.": function(d) { return "Passwort darf nicht fehlen."; },
    "Bad request.": function(d) { return "Fehlerhafte Anfrage."; },
    "Not found.": function(d) { return "Nicht gefunden."; },
    "This combination of email and password is invalid.": function(d) { return "Diese Kombination aus E-Mail-Adresse und Passwort ist ungültig."; },
    "You are not authorized to do this.": function(d) { return "Du bist für diese Aktion nicht authorisiert."; },
    "Email address not verified": function(d) { return "E-Mail-Adresse ist nicht bestätigt"; },
    "An error occurred ()": function(d) { return "Ein Fehler ist aufgetreten (" + d.CODE + ")"; },
    "Password must not be longer than 72 characters.": function(d) { return "Passwort darf nicht länger als 72 Zeichen sein."; },
    "An error occurred.": function(d) { return "Ein Fehler ist aufgetreten."; },
    "Email is invalid.": function(d) { return "E-Mail-Adresse ist ungültig."; },
    "Validation failed for email.": function(d) { return "E-Bestätigung ist fehlgeschlagen."; },
    "Subject must not be empty.": function(d) { return "Betreff darf nicht leer sein"; },
    "Validation failed for subject.": function(d) { return "Prüfung für Betreff ist fehlgeschlagen."; },
    "Message must not be empty.": function(d) { return "Beschreibung darf nicht leer sein."; },
    "Validation failed for message.": function(d) { return "Prüfung für Beschreibung ist fehlgeschlagen"; },
    "Person disabled": function(d) { return "Konto gesperrt"; },
    "Date and/or time are invalid.": function(d) { return "Datum und/oder Uhrzeit sind fehlerhaft"; },
    "": function(d) { return ""; }
  },
  gd: {
    "Email must not be empty.": function(d) { return "TODO"; },
    "Invalid Token": function(d) { return "TODO"; },
    "This email is already verified.": function(d) { return "TODO"; },
    "Email is already taken.": function(d) { return "TODO"; },
    "An error occurred internally.": function(d) { return "TODO"; },
    "Password must not be empty.": function(d) { return "TODO"; },
    "Bad request.": function(d) { return "TODO"; },
    "Not found.": function(d) { return "TODO"; },
    "This combination of email and password is invalid.": function(d) { return "TODO"; },
    "You are not authorized to do this.": function(d) { return "TODO"; },
    "Email address not verified": function(d) { return "TODO"; },
    "An error occurred ()": function(d) { return "TODO (" + d.CODE + ")"; },
    "Password must not be longer than 72 characters.": function(d) { return "TODO"; },
    "An error occurred.": function(d) { return "TODO"; },
    "Email is invalid.": function(d) { return "TODO"; },
    "Validation failed for email.": function(d) { return "TODO"; },
    "Subject must not be empty.": function(d) { return "TODO"; },
    "Validation failed for subject.": function(d) { return "TODO"; },
    "Message must not be empty.": function(d) { return "TODO"; },
    "Validation failed for message.": function(d) { return "TODO"; },
    "Person disabled": function(d) { return "TODO"; },
    "": function(d) { return ""; }
  },
  en: {
    "Email must not be empty.": function(d) { return "Email must not be empty."; },
    "Invalid Token": function(d) { return "Invalid Token"; },
    "This email is already verified.": function(d) { return "This email is already verified."; },
    "Email is already taken.": function(d) { return "Email is already taken."; },
    "An error occurred internally.": function(d) { return "An error occurred internally."; },
    "Password must not be empty": function(d) { return "Password must not be empty"; },
    "Bad request.": function(d) { return "Bad request."; },
    "Not found.": function(d) { return "Not found."; },
    "This combination of email and password is invalid.": function(d) { return "This combination of email and password is invalid."; },
    "You are not authorized to do this.": function(d) { return "You are not authorized to do this."; },
    "Email address not verified": function(d) { return "Email address not verified"; },
    "An error occurred ()": function(d) { return "An error occurred (" + d.CODE + ")"; },
    "Password must not be longer than 72 characters.": function(d) { return "Password must not be longer than 72 characters."; },
    "An error occurred.": function(d) { return "An error occurred."; },
    "Email is invalid.": function(d) { return "Email is invalid."; },
    "Validation failed for email.": function(d) { return "Validation failed for email."; },
    "Subject must not be empty.": function(d) { return "Subject must not be empty."; },
    "Validation failed for subject.": function(d) { return "Validation failed for subject."; },
    "Message must not be empty.": function(d) { return "Message must not be empty."; },
    "Validation failed for message.": function(d) { return "Validation failed for message."; },
    "Person disabled": function(d) { return "Account disabled"; },
    "": function(d) { return ""; }
  }
}
