export const FETCHING_LOGOUT = 'FETCHING_LOGOUT';
export const FETCHING_LOGOUT_SUCCESS = 'FETCHING_LOGOUT_SUCCESS';
export const FETCHING_LOGOUT_FAILURE = 'FETCHING_LOGOUT_FAILURE';
export const RESET_LOGOUT_FORM = 'RESET_LOGOUT_FORM';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_PASSWORD = 'SET_PASSWORD';

/*    
 * This file contains the action creators for LOGOUT
 */

export const setUsername = (username) => {
  return { type: SET_USERNAME, username }
}

export const setPassword = (password) => {
  return { type: SET_PASSWORD, password }
}

export const resetForm = () => {
  return { type: RESET_LOGOUT_FORM }
}

export const logout = (username, password) => {
  return {
    type: FETCHING_LOGOUT,
    username,
    password
  }
}

const initialState = {
  isFetching: false,
  errText: ''
};

const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_LOGOUT:
      return {
        ...state,
        isFetching: true,
        errText: ''
      }
    case FETCHING_LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errText: ''
      }
    case FETCHING_LOGOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errText: action.message
      }
    default:
      return state
        
  }
}

export default logoutReducer
