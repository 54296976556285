import log from "loglevel";
import Messages from "messageformat/messages";
import msgData from "lang/api_messages";

var i18n = new Messages(msgData, 'en')

/*
 * API query helpers
 */

/*
 * Caller to backend API; returns a promise
 *
 * The named parameters can be used like this:
 *  fetchApi(url, { authToken })
 *  fetchApi(url, { query:{access_token:authToken} })
 *  fetchApi(url, { payload: {username:"jon", password:"doe"},
 *                  query:{access_token:authToken} })
 *
 * @param {string} url The full URL to call. Obligatory.
 * @param {object} Named parameters that are deconstructed:
 * @param {string} payload The POST payload as a JSON object; optional.
 * @param {string} authToken The access token.
 * @param {object} query Object of keys and values that gets encoded for URI query.
 * @param {object} method POST, GET etc; defaults to POST
 * @promise
 */
export default (
  url,
  { payload, authToken = "", query, method = "POST" } = {}
) => {
  // construct query parameters:
  const queryStr = query ? `?${serializeQuery(query)}` : "";
  const fullquery = `/api${url}${queryStr}`;

  // see if there's any body payload
  const body = payload ? JSON.stringify(payload) : undefined;

  // add headers
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  authToken && headers.append("X-Access-Token", authToken);

  const obj = {
    headers,
    accept: "application/json",
    method,
    body
  };

  return fetch(fullquery, obj).then(response => handleResult(response));
};

/* convert query into URL query ?key=falue&key=value&... (encoded)
 * e.g. query={access_token: "kasdkfd"} -> ?access_token=kasdkfd
 */
function serializeQuery(obj) {
  return Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join("&");
}

/*
 * Checks the return code of fetch call (in range 200-299)
 * @param {promise} response Fetch's respone.
 * @promise
 */
export const checkStatus = response => {
  if (response.ok) {
    return response;
  }
  log.warn("checkStatus", response.status);
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.text = response.statusText;
  error.response = response;
  error.statusCode = error.response.status;
  throw error;
};

/*
 * Turn backend API errors from loopback into human readable messages.
 * This is where localization could be applied as well.
 */
export const errorConverter = err => {
  switch (err.statusCode) {
    case 500:
      return {
        _error: i18n.get("An error occurred internally.")
      };

    case 400:
      if (err.name === "daysOfWeek") {
        return { daysOfWeek: err.message };
      } else if (err.code === "USERNAME_EMAIL_REQUIRED") {
        return { email: i18n.get("Email must not be empty.") };
      } else if (err.code === "INVALID_TOKEN") {
        return i18n.get("Invalid Token");
      } else if (err.code === "USER_ALREADY_VERIFIED") {
        return i18n.get("This email is already verified.");
      } else if (err.message === "newPassword is a required argument") {
        return {newPassword: i18n.get("Password must not be empty")};
      } else {
        return {
          _error: i18n.get("Bad request.")
        };
      }
    case 404:
      return {
        _error: i18n.get("Not found.")
      };

    case 401:
      if (err.code) {
        switch (err.code) {
          case "LOGIN_FAILED":
            return i18n.get("This combination of email and password is invalid.");
          case "AUTHORIZATION_REQUIRED":
            return i18n.get("You are not authorized to do this.");
          case "LOGIN_FAILED_EMAIL_NOT_VERIFIED":
          case "RESET_FAILED_EMAIL_NOT_VERIFIED":
            return i18n.get("Email address not verified");
          case "INVALID_TOKEN":
            return i18n.get("Invalid Token");
          case "PERSON_DISABLED":
            return i18n.get("Person disabled");
          default:
            return i18n.get("An error occurred ()", {CODE: 401});
        }
      }
      break;

    case 422:
      if (err.code) {
        switch (err.code) {
          case "INVALID_PASSWORD":
            return { password: i18n.get("Password must not be empty.") };
          case "PASSWORD_TOO_LONG":
            return { password: i18n.get("Password must not be longer than 72 characters.") };
          case "INVALID_DATE":
            return { when: i18n.get("Date and/or time are invalid.") };
          default:
            return i18n.get("An error occurred.");
        }
      }
      if (err.details && err.details.codes) {
        if (err.details.codes.email) {
          switch (err.details.codes.email[0]) {
            case "presence":
              return { email: i18n.get("Email must not be empty.") };
            case "uniqueness":
              return { email: i18n.get("Email is already taken.") };
            case "custom.email":
              return { email: i18n.get("Email is invalid.") };
            default:
              return i18n.get("Validation failed for email.");
          }
        }
        if (err.details.codes.subject) {
          switch (err.details.codes.subject[0]) {
            case "presence":
              return { subject: i18n.get("Subject must not be empty.") };
            default:
              return { subject: i18n.get("Validation failed for subject.") };
          }
        }
        if (err.details.codes.message) {
          switch (err.details.codes.message[0]) {
            case "presence":
              return { message: i18n.get("Message must not be empty.") };
            default:
              return { message: i18n.get("Validation failed for message.") };
          }
        }
        if (err.details.codes.when) {
          return { when: i18n.get("Date and/or time are invalid.") };
        }
      }
      return { _error: i18n.get("An error occurred ()", {CODE: 422}) };

    default:
      return { _error: i18n.get("An error occurred ()", {CODE: err.statusCode}) };
  }
};

const isJsonResponse = response => {
  const contentType = response.headers.get("content-type");
  return (
    contentType &&
    contentType.indexOf("application/json") !== -1 &&
    response.status !== 204
  );
};

/*
 * Unwraps return promise from fetch into JSON object
 * @param {promise} response Fetch's respone.
 * @return {object} JSON response data.
 */
export const parseJSON = response => {
  if (isJsonResponse(response)) {
    return response
      .json()
      .then(function(json) {
        //log.debug(`got JSON: ${JSON.stringify(json)}`);
        return json;
      })
      .catch(e => {
        log.warn("Caught error in JSON promise:", e);
        const error = new Error("Server did not return JSON data.");
        throw error;
      });
  } else {
    log.warn("parseJSON(): We haven't got JSON!");
    const error = new Error("Server did not return JSON data.");
    throw error;
  }
};

export function handleResult(response) {
  try {
    checkStatus(response);
    if (isJsonResponse(response)) {
      return parseJSON(response);
    } else {
      return {};
    }
  } catch (error) {
    log.warn("handleResponse caught", error);
    if (isJsonResponse(response)) {
      return parseJSON(response).then(json => ({
        error: {
          text: errorConverter(json.error)
        }
      }));
    } else {
      return {
        error: {
          text: error.text
        }
      };
    }
  }
}

export function setLang(lang) {
  i18n = new Messages(msgData, lang)
};
