import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga';
import rootReducer from './allReducers';
import { loadState, saveState } from './localStorage'
import { setLang } from 'helpers/fetchApi';

/*
 * Create Redux stor out of all reducers that are defined in components
 * and add enhancers like Redux devtools and Saga middleware.
 */

// add Redux dev tools to browser window
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// create Redux store with dev tools, app reducer and redux-saga
export default function configureStore() {

  const sagaMiddleware = createSagaMiddleware()

  const persistedState = loadState();
  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(
      applyMiddleware(sagaMiddleware)
    )
  );
  
  store.subscribe(() => {
    saveState({
      login: store.getState().login,
      uistate: store.getState().uistate,
    });
  });

  store.subscribe(() => {
    setLang(store.getState().uistate.language)
  });


  rootSaga.map(saga => sagaMiddleware.run(saga));
  
  return store
}

