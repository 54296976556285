export const SEND_CONFIRM_SUBMIT = "SEND_CONFIRM_SUBMIT";
export const SEND_CONFIRM_SUCCESS = "SEND_CONFIRM_SUCCESS";
export const SEND_CONFIRM_FAILURE = "SEND_CONFIRM_FAILURE";

export const sendConfirm = (uid, token) => ({
  type: SEND_CONFIRM_SUBMIT,
  uid,
  token
});

const initialState = {
  apiError: undefined,
  success: undefined
};

const emailConfirm = (state = initialState, action) => {
  switch (action.type) {
    case SEND_CONFIRM_SUBMIT:
      return {
        ...state,
        apiError: undefined,
        success: undefined
      };

    case SEND_CONFIRM_SUCCESS:
      return {
        ...state,
        success: true
      };

    case SEND_CONFIRM_FAILURE:
      return {
        ...state,
        apiError: action.apiError,
        success: false
      };

    default:
      return state;
  }
};

export default emailConfirm;
