import { connect } from 'react-redux'
import { logout } from './LogoutDuck.js'
import Logout from './Logout'

/*
 * Redux container for Logout
 */

const mapStateToProps = (state) => ({
  isFetching: state.logout.isFetching,
  isLoggedIn: !!state.login.authToken,
  errText: state.logout.errText
})

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => {
      dispatch(logout())
    },
  }
}

const LogoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout)

export default LogoutContainer;

