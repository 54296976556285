import { connect } from "react-redux";

import { getMessages } from "../../helpers/helpers";
import { setLanguage, resetForm, signup } from "./SignupDuck";
import Signup from "./Signup";

/*
 * Redux container for Signup
 */

const mapStateToProps = state => {
  const { signup } = state;
  return {
    username: signup.username,
    password: signup.password,
    isFetching: signup.isFetching,
    isLoggedIn: !!state.login.authToken,
    isSignedUp: signup.isSignedUp,
    apiError: signup.apiError ? signup.apiError : "",
    messages: getMessages(state),
    language: state.uistate.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetForm: () => {
      dispatch(resetForm());
    },
    onLanguageChange: p => {
      dispatch(setLanguage(p));
    },
    onSubmit: (username, password, language) => {
      dispatch(signup(username, password, language));
    }
  };
};

const SignupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);

export default SignupContainer;
