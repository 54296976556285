import log from "loglevel";
import { toast } from "react-toastify";
import { showToast, getLocalTimeFromDateStr, paddedNtoS } from "../../helpers/helpers";
import { put, takeEvery, call, select } from "redux-saga/effects";
import addMinutes from "date-fns/addMinutes";

import fetchApi from "helpers/fetchApi";
import { elliptic } from "helpers/helpers";


import {
  CLOSE_MANAGE_MODAL,
  DELETE_MEMO_FAILURE,
  DELETE_MEMO_SUCCESS,
  MARK_DELETE_MEMO,
  POST_MEMO,
  POST_MEMO_FAILURE,
  POST_MEMO_SUCCESS,
  SET_MEMO
} from "./MemoFormDuck";
import { RT_DAILY, RT_MONTHLY } from "./constants";
import { SET_SELECTED_MEMO_ID } from "../MemoTable/MemoTableDuck";

export const DEFAULT_TIMEZONE =  (((Intl || {}).DateTimeFormat() || {}).resolvedOptions() || {}).timeZone || "Europe/London"; 
log.debug('Default Timezone', DEFAULT_TIMEZONE);

/*
 * Sagas to handle calls to backend API
 */

// inserts or updates Memo
export function* doPostMemoSaga({ memo }) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const user = yield select(state => state.login);
    const userId = user.userId;
    const {
      id,
      when, // e.g "2019-05-08T17:00:00.000Z"
      timeZone,
      subject,
      message,
      sent,
      isRecurring,
      recurOptions,
      preNotificationOptions,
      deleted
    } = memo;

    // clean up form values
    if (isRecurring) {
      recurOptions.type !== RT_DAILY && (recurOptions.daysOfWeek = undefined);
      recurOptions.type !== RT_MONTHLY &&
        (recurOptions.monthOption = undefined);
    } else {
      if (recurOptions)
        Object.keys(recurOptions).forEach(function(key) {
          delete recurOptions[key];
        });
    }

    // construct a new date string from date/time input forms, making sure
    // it is in local time without any browser timezone interfering
    const tzOffsetMins = new Date().getTimezoneOffset();
    let whenLocal = addMinutes(when, -tzOffsetMins);
    const year = paddedNtoS(whenLocal.getUTCFullYear());
    const month = paddedNtoS(whenLocal.getUTCMonth()+1);
    const day = paddedNtoS(whenLocal.getUTCDate());
    const hour = paddedNtoS(whenLocal.getUTCHours());
    const minute = paddedNtoS(whenLocal.getUTCMinutes());

    const whenISOUTC = `${year}-${month}-${day}T${hour}:${minute}:00.000Z`;

    const payload = {
      when: whenISOUTC,
      timeZone,
      subject: subject.trim(),
      message: (message && message.trim()) || "",
      // normal saving should trigger "unsent", deleting should keep value:
      sent: deleted ? sent : false,
      isRecurring,
      recurOptions,
      preNotificationOptions,
      deleted
    };
    const create = !id;
    let url;
    if (create) url = `/Persons/${userId}/Memos`;
    else url = `/Persons/${userId}/Memos` + (id ? `/${id}` : "");

    // call API
    const method = !create ? "PUT" : "POST";
    const jsonData = yield call(fetchApi, url, { payload, method, authToken });

    // ... and update Redux store
    if (!jsonData.error) {
      const memo = jsonData;
			memo.time = getLocalTimeFromDateStr(memo.when);
      yield put({ type: POST_MEMO_SUCCESS, memo, create });
      yield put({ type: CLOSE_MANAGE_MODAL });

      return memo;
    } else {
      yield put({ type: POST_MEMO_FAILURE, apiError: jsonData.error.text });
    }
  } catch (error) {
    log.error("POST_MEMO_FAILURE", error);
    yield put({ type: POST_MEMO_FAILURE, apiError: "Network failure" });
  }
}

// calls DELETE endpoint to really delete a memo
export function* deleteMemoSaga(action) {
  try {
    // const id = yield select(state => state.memoform.memo.id);
    const { id, personId, memo } = action;
    const authToken = yield select(state => state.login.authToken);
    const url = `/persons/${personId}/memos/${id}`;
    const jsonData = yield call(fetchApi, url, { method: "DELETE", authToken });

    if (!jsonData.error) {
      yield put({ type: DELETE_MEMO_SUCCESS, id, memo });
      toast(`Deleted Memo ${elliptic(memo.subject, 20)}`, {
        className: "App__toast--ok"
      });
      yield put({ type: SET_SELECTED_MEMO_ID, memoId: undefined });
    } else {
      yield put({
        type: DELETE_MEMO_FAILURE,
        apiError: jsonData.error.text
      });
    }
  } catch (error) {
    log.error("DELETE_MEMO_FAILURE", error.message);
  }
}

export function* updateForm(memo) {
  yield put({ type: SET_MEMO, memo });
  yield put({ type: SET_SELECTED_MEMO_ID, memoId: memo.id });
}

// sets the memo's "deleted" field to "now" date and saves it
export function* markMemoDeleted({ memo }) {
  try {
    const dmemo = { ...memo };
    dmemo.deleted = new Date();
    const updatedmemo = yield call(doPostMemoSaga, { memo: dmemo });
    if (updatedmemo) {
      yield call(showToast, "Upsert toast", {
        METHOD: 'deleted',
        SUBJECT: elliptic(updatedmemo.subject, 20)
      },
        "App__toast--ok");

      yield put({ type: DELETE_MEMO_SUCCESS, id: memo.id, updatedmemo });
    }
  } catch (error) {
    log.error("mark as deleted error", error);
  }
}

export function* upsertMemo(action) {
  const updated = action.memo.id;
  const memo = yield call(doPostMemoSaga, action);
  if (memo) {
    yield call(updateForm, memo);
    yield call(showToast, "Upsert toast", {
      METHOD: updated ? "updated" : "added",
      SUBJECT: elliptic(memo.subject, 20)
    });
  }
}

// listen for actions of type POST_MEMO and use them
export default function* postMemoSaga() {
  yield takeEvery(POST_MEMO, upsertMemo);
  yield takeEvery(MARK_DELETE_MEMO, markMemoDeleted);
}
