export const FETCHING_SIGNUP = "FETCHING_SIGNUP";
export const FETCHING_SIGNUP_SUCCESS = "FETCHING_SIGNUP_SUCCESS";
export const FETCHING_SIGNUP_FAILURE = "FETCHING_SIGNUP_FAILURE";
export const SET_SIGNUP_LANGUAGE = "SET_SIGNUP_LANGUAGE";
export const CLEAR_SIGNUP_DATA = "CLEAR_SIGNUP_DATA";
export const  REQUEST_VERIFICATION = "REQUEST_VERIFICATION";
export const  REQUEST_VERIFICATION_SUCCESS = "REQUEST_VERIFICATION_SUCCESS";
export const  REQUEST_VERIFICATION_FAILURE = "REQUEST_VERIFICATION_FAILURE";

/*
 * This file contains the action creators for Signup
 */

export const setLanguage = language => ({
  type: SET_SIGNUP_LANGUAGE,
  language
});

export const resetForm = () => {
  return { type: CLEAR_SIGNUP_DATA };
};

export const signup = (username, password, language) => {
  return {
    type: FETCHING_SIGNUP,
    username,
    password,
    language
  };
};

export const requestVerificationEmail = id => ({
  type: REQUEST_VERIFICATION,
  id
});

const initialState = {
  username: "",
  password: "",
  isFetching: false,
  apiError: ""
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SIGNUP_DATA:
      // keep the value of isSignedUp, so the Signup form can show a success message
      return { ...state, ...initialState };

    case SET_SIGNUP_LANGUAGE:
      return {
        ...state,
        language: action.language,
        apiError: undefined
      };
    case FETCHING_SIGNUP:
      return {
        ...state,
        isFetching: true,
        isSignedUp: false,
        apiError: undefined
      };
    case FETCHING_SIGNUP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSignedUp: true
      };
    case FETCHING_SIGNUP_FAILURE:
      return {
        ...state,
        isFetching: false,
        apiError: action.apiError,
        isSignedUp: false
      };

    case REQUEST_VERIFICATION:
      return {
        ...state,
        isFetching: true,
        apiError: undefined,
        success: undefined
      };

    case REQUEST_VERIFICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: true
      };

    case REQUEST_VERIFICATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        apiError: action.apiError,
        success: false
      };

    default:
      return state;
  }
};

export default signupReducer;
