export const RESET_PASSWORD_SUBMIT = 'RESET_PASSWORD_SUBMIT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const REQUEST_PASSWORD_SUBMIT = 'REQUEST_PASSWORD_SUBMIT';
export const REQUEST_PASSWORD_SUCCESS = 'REQUEST_PASSWORD_SUCCESS';
export const REQUEST_PASSWORD_FAILURE = 'REQUEST_PASSWORD_FAILURE';
export const RESET_PASSWORD_RESET_DATA = 'RESET_PASSWORD_RESET_DATA';

export const setNewPassword = newPassword => ({
  type: SET_NEW_PASSWORD,
  newPassword
});

export const submitNewPassword = (newPassword, access_token) => ({
  type: RESET_PASSWORD_SUBMIT,
  newPassword,
  access_token
});

export const requestPasswordReset = email => ({
  type: REQUEST_PASSWORD_SUBMIT,
  email
});

export const resetData = () => ({
  type: RESET_PASSWORD_RESET_DATA
});

const initialState = {
  isFetching: false,
  success: undefined,
  apiError: undefined,
  newPassword: ''
};

const passwordReset = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.newPassword
      };

    case REQUEST_PASSWORD_SUBMIT:
    case RESET_PASSWORD_SUBMIT:
      return {
        ...state,
        isFetching: true,
        success: undefined,
        apiError: undefined
      }

    case REQUEST_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: true,
        apiError: undefined,
        newPassword: ''
      }

    case REQUEST_PASSWORD_FAILURE:
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        success: false,
        apiError: action.apiError,
        newPassword: ''
      }

    case RESET_PASSWORD_RESET_DATA:
      return initialState;
      
    default:
      return state;
  }
}

export default passwordReset;
