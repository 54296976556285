import log from "loglevel";
import {
  LANG_DEFAULT,
  supportedLanguages
} from "components/common/LanguageChooser";

export const SET_LANGUAGE = "SET_LANGUAGE";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_TIMEZONE = "CHANGE_TIMEZONE";
export const CHANGE_ALLPAUSED = "CHANGE_ALLPAUSED";
export const CHANGE_HIDEHINTS = "CHANGE_HIDEHINTS";
export const SHOW_SETTINGS = "SHOW_SETTINGS";
export const SHOW_USER_DETAILS = "SHOW_USER_DETAILS";

export const setLanguage = language => ({
  type: SET_LANGUAGE,
  language
});

export const changeLanguage = (language, updateUserSettings) => ({
  type: CHANGE_LANGUAGE,
  language,
  updateUserSettings
});

export const changeTimeZone = timeZone => ({
  type: CHANGE_TIMEZONE,
  timeZone
});

export const changeAllPaused = allPaused => ({
  type: CHANGE_ALLPAUSED,
  allPaused
});

export const changeHideHints = hideHints => ({
  type: CHANGE_HIDEHINTS,
  hideHints
});

export const showSettings = open => ({
  type: SHOW_SETTINGS,
  open
});

export const showUserDetails = (open, id) => ({
  type: SHOW_USER_DETAILS,
  open,
  id
});

// to force a specific language, hard wire it like so:
// const BROWSER_LANGUAGE = 'de' ||

const BROWSER_LANGUAGE =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  LANG_DEFAULT;
log.debug("Browser language:", BROWSER_LANGUAGE);

export const BROWSER_TIMEZONE =
  (((Intl || {}).DateTimeFormat() || {}).resolvedOptions() || {}).timeZone ||
  "Europe/London";
log.debug("Browser Timezone", BROWSER_TIMEZONE);

const initialState = {
  language: supportedLanguages( BROWSER_LANGUAGE),
  timeZone: BROWSER_TIMEZONE,
  settingsModalOpen: false,
  userDetailsModalOpen: false
};

const uistate = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: supportedLanguages(action.language)
      };

    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: supportedLanguages(action.language)
      };

    case CHANGE_TIMEZONE:
      return {
        ...state,
        timeZone: action.timeZone
      };

    case CHANGE_ALLPAUSED:
      return {
        ...state,
        allPaused: action.allPaused
      };

    case CHANGE_HIDEHINTS:
      return {
        ...state,
        hideHints: action.hideHints
      };

    case SHOW_SETTINGS:
      return {
        ...state,
        settingsModalOpen: action.open
      };

    case SHOW_USER_DETAILS:
      return {
        ...state,
        userDetailsModalOpen: action.open && action.id
      };

    default:
      return state;
  }
};

export default uistate;
