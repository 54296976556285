import log from "loglevel";
import { put, takeEvery, call, select } from "redux-saga/effects";
import fetchApi from "../../helpers/fetchApi";
import {
  FETCH_USERS_SUBMIT,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  CHANGE_USER_DETAILS_SUBMIT,
  CHANGE_USER_DETAILS_SUCCESS,
  CHANGE_USER_DETAILS_FAILURE,
  DELETE_USER_SUBMIT,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
} from "./UsersDuck";

export function* fetchUsers(action) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const url = "/persons";
    const jsonData = yield call(fetchApi, url, {
      method: "GET",
      authToken
    });
    if (!jsonData.error) {
      yield put({ type: FETCH_USERS_SUCCESS, users: jsonData });
    } else {
      yield put({
        type: FETCH_USERS_FAILURE,
        apiError: jsonData.error.text._error
          ? jsonData.error.text._error
          : jsonData.error.text
      });
    }
  } catch (err) {
    log.warn("fetchUsers err", err);
  }
}

export function* changeUserDetails({ user }) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const url = `/persons/${user.id}`;
    const { id, ...payload } = user;
    const jsonData = yield call(fetchApi, url, {
      method: "PATCH",
      payload,
      authToken
    });
    if (!jsonData.error) {
      yield put({ type: CHANGE_USER_DETAILS_SUCCESS, user: jsonData });
    } else {
      yield put({
        type: CHANGE_USER_DETAILS_FAILURE,
        apiError: jsonData.error.text._error
          ? jsonData.error.text._error
          : jsonData.error.text
      });
    }
  } catch (err) {
    log.warn("fetchUserDetails err", err);
    yield put({
      type: CHANGE_USER_DETAILS_FAILURE,
      apiError: "Network error."
    });
  }
}

export function* markUserDeleted({ user }) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const url = `/persons/markDeleted`;
    const { id } = user;
    const jsonData = yield call(fetchApi, url, {
      method: "POST",
      payload: {id},
      authToken
    });
    if (!jsonData.error) {
      yield put({ type: DELETE_USER_SUCCESS, user: jsonData });
    } else {
      yield put({
        type: DELETE_USER_FAILURE,
        apiError: jsonData.error.text._error
          ? jsonData.error.text._error
          : jsonData.error.text
      });
    }
  } catch (err) {
    log.warn("markUserDeleted err", err);
    yield put({
      type: DELETE_USER_FAILURE,
      apiError: "Network error."
    });
  }
}

export default function* loginSaga() {
  yield takeEvery(FETCH_USERS_SUBMIT, fetchUsers);
  yield takeEvery(CHANGE_USER_DETAILS_SUBMIT, changeUserDetails);
  yield takeEvery(DELETE_USER_SUBMIT, markUserDeleted);
}
