import "react-toastify/dist/ReactToastify.css";

import "./App.css";

import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer, Slide, toast } from "react-toastify";

import Confirm from "./components/Confirm";
import asyncComponent from "./components/AsyncComponent";
import Signup from "./components/Signup";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Verified from "./components/Verified";
import PasswordReset from "./components/PasswordReset";

const AsyncHome = asyncComponent(() => import("./components/Home"));
const AsyncAdminHome = asyncComponent(() => import("./components/AdminHome"));

class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <div className="App">
            <Route exact path="/" component={AsyncHome} />
            <Route exact path="/admin" component={AsyncAdminHome} />
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/confirm" component={Confirm} />
            <Route path="/verified" component={Verified} />
            <Route path="/reset-password" component={PasswordReset} />
            <ToastContainer
              position={toast.POSITION.BOTTOM_LEFT}
              transition={Slide}
              hideProgressBar
              toastClassName="App__toast"
            />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
