import { combineReducers } from 'redux';
import emailConfirm from '../components/Confirm/ConfirmDuck';
import signup from '../components/Signup/SignupDuck';
import login from '../components/Login/LoginDuck';
import logout from '../components/Logout/LogoutDuck';
import memos from '../components/MemoTable/MemoTableDuck';
import memoform from '../components/MemoForm/MemoFormDuck';
import users from '../components/Users/UsersDuck';
import passwordReset from '../components/PasswordReset/PasswordResetDuck';
import uistate from '../uistate';

/*
 * Collect all reducers from components here: each time a Redux enabled component
 * is added, update the allReducers.
 */

const allReducers = combineReducers({
  emailConfirm,
  signup,
  login,
  logout,
  memos,
  memoform,
  users,
  passwordReset,
  uistate
});

/*
 * Clear Redux store on successful Logout.
 */
const rootReducer = (state, action) => {
  if (action.type === 'FETCHING_LOGOUT_SUCCESS' || action.type === 'FETCHING_LOGOUT_FAILURE') {
    state = undefined
  }

  return allReducers(state, action)
}

export default rootReducer;

