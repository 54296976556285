/* eslint-disable */ 
var de = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1];
  if (ord) return 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var gd = function(n, ord
) {
  var s = String(n).split('.'), t0 = Number(s[0]) == n;
  if (ord) return ((n == 1
          || n == 11)) ? 'one'
      : ((n == 2
          || n == 12)) ? 'two'
      : ((n == 3
          || n == 13)) ? 'few'
      : 'other';
  return ((n == 1
          || n == 11)) ? 'one'
      : ((n == 2
          || n == 12)) ? 'two'
      : (((t0 && n >= 3 && n <= 10)
          || (t0 && n >= 13 && n <= 19))) ? 'few'
      : 'other';
};
var fr = function(n, ord
) {
  if (ord) return (n == 1) ? 'one' : 'other';
  return (n >= 0 && n < 2) ? 'one' : 'other';
};
var en = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1], t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
  if (ord) return (n10 == 1 && n100 != 11) ? 'one'
      : (n10 == 2 && n100 != 12) ? 'two'
      : (n10 == 3 && n100 != 13) ? 'few'
      : 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var select = function(value, data) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  return data.other;
};
var plural = function(value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  if (key in data) return data[key];
  return data.other;
};
var fmt = {
  date: function (v,lc,p) {
    var o = {day:'numeric', month:'short', year:'numeric'};
    switch (p) {
      case 'full': o.weekday = 'long';
      case 'long': o.month = 'long'; break;
      case 'short': o.month = 'numeric';
    }
    return (new Date(v)).toLocaleDateString(lc, o)
  }
};

module.exports = {
  de: {
    Memomolecule: function(d) { return "Erinnerungsmolekül"; },
    "Internal Server Error": function(d) { return "Interner Fehler, versuch es später nochmal!"; },
    every: function(d) { return plural(d.NUMBER, 0, de, { "0": select(d.GENDER, { m: select(d.KASUS, { nom: "kein", gen: "keines", dat: "keinem", acc: "keinen", other: "kein" }), f: select(d.KASUS, { nom: "keine", gen: "keiner", dat: "keiner", acc: "keine", other: "keine" }), n: select(d.KASUS, { nom: "kein", gen: "keines", dat: "keinem", acc: "keinen", other: "kein" }), other: "x" }), one: select(d.GENDER, { m: select(d.KASUS, { nom: "jeder", gen: "jedes", dat: "jedem", acc: "jeden", other: " jeden" }) + " ", f: "jede", n: "jedes", other: "jed" }), other: "alle" }); },
    daily: function(d) { return select(d.GEND, { m: "täglich", f: "tägliche", n: "tägliches", other: "jeden Tag" }); },
    weekly: function(d) { return "wöchentlich"; },
    monthly: function(d) { return "monatlich"; },
    yearly: function(d) { return "jährlich"; },
    minute: function(d) { return plural(d.NUMBER, 0, de, { "1": "Minute", other: "Minuten" }); },
    hour: function(d) { return plural(d.NUMBER, 0, de, { "1": "Stunde", other: "Stunden" }); },
    day: function(d) { return plural(d.NUMBER, 0, de, { "1": "Tag", other: "Tage" }); },
    week: function(d) { return plural(d.NUMBER, 0, de, { "0": "Woche", "1": "Woche", other: "Wochen" }); },
    month: function(d) { return plural(d.NUMBER, 0, de, { "1": "Monat", other: "Monate" }); },
    year: function(d) { return plural(d.NUMBER, 0, de, { "1": "Jahr", other: "Jahre" }); },
    "Next email is due on": function(d) { return "Die nächste E-Mail " + select(d.TEMPUS, { other: "ist", past: "war" }) + " fällig am " + fmt.date(d.T, "de", "full"); },
    "Next prenotification is due on": function(d) { return "Die nächste Vorbenachrichtigung " + select(d.TEMPUS, { other: "ist", past: "war" }) + " fällig am " + fmt.date(d.T, "de", "full"); },
    "Next prenotification:": function(d) { return "Nächste Vorbenachrichtigung: " + fmt.date(d.T, "de", "full"); },
    Monday: function(d) { return "Montag"; },
    Tuesday: function(d) { return "Dienstag"; },
    Wednesday: function(d) { return "Mittwoch"; },
    Thursday: function(d) { return "Donnerstag"; },
    Friday: function(d) { return "Freitag"; },
    Saturday: function(d) { return "Samstag"; },
    Sunday: function(d) { return "Sonntag"; },
    "only on these days of week": function(d) { return "nur an diesen Wochentagen"; },
    Recur: function(d) { return "Wiederholung"; },
    "The e-mail subject will be:": function(d) { return "Der E-Mail Betreff ist:"; },
    "An e-mail will be sent:": function(d) { return "Eine E-Mail wird gesendet:"; },
    "The Memomolecule will repeat automatically:": function(d) { return "Das Erinnerungsmolekül wiederholt sich automatisch:"; },
    "Invalid Date Format": function(d) { return "Falsches Datumsformat"; },
    "Click \"+\" to add one more line.": function(d) { return "Drücke \"+\", um eine weitere Zeile hinzuzufügen."; },
    "The recurrence should be:": function(d) { return "Die Wiederholung soll sein:"; },
    "With this interval:": function(d) { return "Mit diesem Intervall:"; },
    Recurring: function(d) { return "Aktiviere eine Wiederholung"; },
    "Activate a Recurrence": function(d) { return "Aktiviere eine Wiederholung"; },
    "You can add intervals by typing them as numbers into the field.": function(d) { return "Du kannst weitere Intervalle hinzufügen, indem Du sie als Zahlen in das Feld eingibst."; },
    "When:": function(d) { return "Wann:"; },
    "There are multiple options for this day:": function(d) { return "Für diesen Tag gibt es mehrere Möglichkeiten:"; },
    on: function(d) { return "am"; },
    "Must be a weekday on or before this day.": function(d) { return "Muß ein Werktag an oder vor diesem Tag sein."; },
    "of month": function(d) { return "des Monats"; },
    "on first day of month": function(d) { return "am ersten Tag des Monats"; },
    "on last day of month": function(d) { return "am letzten Tag des Monats"; },
    "on first": function(d) { return "am ersten"; },
    "on last": function(d) { return "am letzten"; },
    "Activate Prenotifications": function(d) { return "Aktiviere Vorbenachrichtigungen"; },
    "Prenotifications are sent at fixed intervals before the actual notification time.": function(d) { return "Vorbenachrichtigungen werden zu festgelegten Intervallen vor der eigentlichen Benachrichtigungszeit gesendet."; },
    add: function(d) { return "hinzufügen"; },
    remove: function(d) { return "entfernen"; },
    "in X days": function(d) { return "in " + d.DAYS + " " + plural(d.DAYS, 0, de, { one: "Tag", other: "Tagen" }); },
    "in X hours": function(d) { return "in " + d.HOURS + " " + plural(d.HOURS, 0, de, { one: "Stunde", other: "Stunden" }); },
    "X days ago": function(d) { return "vor " + d.DAYS + " " + plural(d.DAYS, 0, de, { one: "Tag", other: "Tagen" }); },
    "X hours ago": function(d) { return "vor " + d.HOURS + " " + plural(d.HOURS, 0, de, { one: "Stunde", other: "Stunden" }); },
    "No days of week defined.": function(d) { return "Keine Wochentage ausgewählt."; },
    "All memo molecules are paused.": function(d) { return "Alle Erinnerungsmoleküle sind pausiert"; },
    "There are currently no prenotifications scheduled.": function(d) { return "Momentan sind keine Vorbenachrichtigungen geplant."; },
    "Upsert toast": function(d) { return select(d.METHOD, { added: "Neues Erinnerungsmolekül", deleted: "Gelöscht", updated: "Änderung gespeichert", other: "other" }) + " " + d.SUBJECT; },
    "Minute values must be multiples of": function(d) { return "Minutenwerte müssen Vielfache von  " + d.NUMBER + " sein."; },
    "You are logged in as": function(d) { return "Du bist eingeloggt als " + d.USER + "."; },
    Signup: function(d) { return "Registrieren"; },
    Login: function(d) { return "Einloggen"; },
    Logout: function(d) { return "Ausloggen"; },
    "Register for a new account": function(d) { return "Erstelle ein neues Konto"; },
    "You will get an e-mail in the next minutes with a verification link.": function(d) { return "Du bekommst in den nächsten Minuten eine E-Mail mit einem Bestätigungs-Link."; },
    "Please check your e-mails and verify your account.": function(d) { return "Prüfe bitte Deine E-Mails und bestätige Dein Konto."; },
    "Add our e-mail address 'noreply@memomolecule.com' to your spam whitelist.": function(d) { return "Füge unsere E-Mail-Adresse 'noreply@memomolecule.com' zu Deiner Spam-Whitelist hinzu."; },
    "Check your e-mails for a password reset link": function(d) { return "Prüfe bitte Deine E-Mails auf einen Link, um das Passwort zurückzusetzen."; },
    "Back to the app": function(d) { return "Zurück zur App"; },
    "Log in to your account": function(d) { return "In's Konto einloggen"; },
    "All your memos": function(d) { return "Alle Deine Erinnerungsmoleküle"; },
    "Unsent Memos": function(d) { return "Ungesendete Erinnerungsmoleküle"; },
    "Sent Memos": function(d) { return "Gesendete Erinnerungsmoleküle"; },
    "Add a new memo": function(d) { return "Erzeuge ein Erinnerungsmolekül"; },
    "When should the memo be sent?": function(d) { return "Wann soll das Erinnerungsmolekül starten?"; },
    Timezone: function(d) { return "Zeitzone"; },
    Unsent: function(d) { return "Ungesendet"; },
    Sent: function(d) { return "Gesendet"; },
    "Edit this memo": function(d) { return "Ändere dieses Erinnerungsmolekül"; },
    "Email subject": function(d) { return "Betreff"; },
    "A short note": function(d) { return "Kurztitel"; },
    "A longer description (optional)": function(d) { return "Eine längere Beschreibung (optional)"; },
    Save: function(d) { return "Speichern"; },
    Cancel: function(d) { return "Abbrechen"; },
    Delete: function(d) { return "Löschen"; },
    "Fetched all memos": function(d) { return "Alle Memos geladen"; },
    "Signed Up!": function(d) { return "Registriert!"; },
    "E-mail address": function(d) { return "E-Mail-Adresse"; },
    "Memo saved successfully!": function(d) { return "Erinnerungsmolekül gespeichert!"; },
    "Your email address is verified.": function(d) { return "Deine E-Mail-Adresse ist bestätigt."; },
    "You can now": function(d) { return "Du kannst dich jetzt"; },
    "log in": function(d) { return "einloggen"; },
    "The verification failed. Make sure to use the latest email link.": function(d) { return "Die E-Mail-Bestätigung schlug fehl. Stelle sicher, daß Du den Link aus der neuesten E-Mail benutzt."; },
    "Your email might already be verified and you can now": function(d) { return "Die E-Mail-Adresse ist möglicherweise schon bestätigt und Du kannst dich jetzt"; },
    "Need a": function(d) { return "Möchtest Du eine"; },
    "new verification email?": function(d) { return "erneute Bestätigungs-Email?"; },
    "This email is already verified.": function(d) { return "Diese Email ist schon bestätigt."; },
    Send: function(d) { return "Sende"; },
    "Request a new verification email": function(d) { return "Bestelle eine neue Bestätigungs-E-Mail"; },
    "Password changed successfully!": function(d) { return "Passwort erfolgreich geändert!"; },
    "Enter a new password": function(d) { return "Gib ein neues Passwort ein"; },
    "Manage User Settings": function(d) { return "Bearbeite Benutzereinstellungen"; },
    and: function(d) { return "und"; },
    Language: function(d) { return "Sprache"; },
    "Select a Time Zone": function(d) { return "Wähle eine Zeitzone"; },
    "A different timezone from user settings can be chosen.": function(d) { return "Eine andere Zeitzone als die aus den Benutzereinstellungen kann ausgewählt werden."; },
    "Time Zone changes will only affect newly created memo molecules!": function(d) { return "Änderungen der Zeitzone wirken sich nur auf neu erstellte Erinnerungsmoleküle aus!"; },
    "All future emails will now use this language and date/time format!": function(d) { return "Alle zukünftigen E-Mails werden Sprache, Datums- und Zeitformat berücksichtigen!"; },
    "Reload memos": function(d) { return "Erinnerungsmoleküle neu laden"; },
    "Create Memo": function(d) { return "Erzeuge ein neues Erinnerungsmolekül"; },
    "All paused": function(d) { return "Alle pausiert"; },
    "If checked, no emails will be sent.": function(d) { return "Wenn angekreuzt, werden keine E-Mails verschickt."; },
    "Hide helping hints": function(d) { return "Unterdrücke Tipps."; },
    "If checked, no on-screen hint labels will be shown.": function(d) { return "Wenn angekreuzt, werden keine Tipps angezeigt."; },
    "Choose a different time zone": function(d) { return "Wähle eine andere Zeitzone"; },
    "A time zone change will recalculate all Memos without an explicit time zone.": function(d) { return "Eine Änderung der Zeitzone berechnet alle Erinnerungsmoleküle neu, die keine explizite Zeitzone haben."; },
    "If you have recently signed up, have you verified your email?": function(d) { return "Falls Du Dich vor kurzem registriert hast, hast Du Deine E-Mail-Adresse bestätigt?"; },
    "Reset Password": function(d) { return "Password zurücksetzen"; },
    "There is no data to show. To add a new Memo molecule, press this button: ": function(d) { return "Es gibt keine Daten, die angezeigt werden können. Um ein neues Erinnerungsmolekül hinzuzufügen, drücke auf diesen Knopf: "; },
    "There is no data to show.": function(d) { return "Es gibt keine Daten, die angezeigt werden können."; },
    "No data fetched yet.": function(d) { return "Noch keine Daten geladen"; },
    mth1: function(d) { return d.DAY + ". Jan, " + d.YEAR; },
    mth2: function(d) { return d.DAY + ". Feb, " + d.YEAR; },
    mth3: function(d) { return d.DAY + ". Mär, " + d.YEAR; },
    mth4: function(d) { return d.DAY + ". Apr, " + d.YEAR; },
    mth5: function(d) { return d.DAY + ". Mai, " + d.YEAR; },
    mth6: function(d) { return d.DAY + ". Jun, " + d.YEAR; },
    mth7: function(d) { return d.DAY + ". Jul, " + d.YEAR; },
    mth8: function(d) { return d.DAY + ". Aug, " + d.YEAR; },
    mth9: function(d) { return d.DAY + ". Sep, " + d.YEAR; },
    mth10: function(d) { return d.DAY + ". Okt, " + d.YEAR; },
    mth11: function(d) { return d.DAY + ". Nov, " + d.YEAR; },
    mth12: function(d) { return d.DAY + ". Dez, " + d.YEAR; },
    dow0: function(d) { return "Sonntag"; },
    dow1: function(d) { return "Montag"; },
    dow2: function(d) { return "Dienstag"; },
    dow3: function(d) { return "Mittwoch"; },
    dow4: function(d) { return "Donnerstag"; },
    dow5: function(d) { return "Freitag"; },
    dow6: function(d) { return "Samstag"; },
    dow7: function(d) { return "Sonntag"; },
    Help: function(d) { return "Hilfe"; },
    "Up to x additional e-mail can be sent before:": function(d) { return "Bis zu " + d.NUMBER + " zusätzliche E-Mails können vorher gesendet werden:"; },
    Documentation: function(d) { return "Dokumentation"; },
    "Privacy policy": function(d) { return "Privatsphäre"; },
    "Terms of Service": function(d) { return "Bedingungen"; },
    Contact: function(d) { return "Kontakt"; },
    "Memo Molecule": function(d) { return "Erinnerungs-Molekül"; },
    "The powerful rules let you set reminders like:": function(d) { return "Unendliche und flexible Möglichkeiten:"; },
    "These little fellows come to your email and remind you!": function(d) { return "Diese Moleküle kommen in Dein E-Mail-Konto und erinnern Dich!"; },
    "Next Monday.": function(d) { return "Nächsten Montag."; },
    "Later today.": function(d) { return "Später am heutigen Tag."; },
    "Every year on the same day.": function(d) { return "Jedes Jahr am selben Tag."; },
    "Every first day of the month.": function(d) { return "Jeden Ersten des Monats."; },
    "Every last working day of the month.": function(d) { return "Jeden letzten Werktag im Monat."; },
    "Every week on a Thursday.": function(d) { return "Jeden Donnerstag."; },
    "Every third Saturday in the month. ": function(d) { return "Jeden dritten Samstag im Monat."; },
    "Every two weeks.": function(d) { return "Alle zwei Wochen."; },
    "Every Monday, Wednesday and Friday.": function(d) { return "Jeden Montag, Mittwoch und Freitag."; },
    "Every month on the 5th.": function(d) { return "Jeden Monat am Fünften."; },
    "Every last Wednesday of the month.": function(d) { return "Jeden letzten Mittwoch im Monat."; },
    "On the last day of every second month.": function(d) { return "Alle zwei Monate am letzten Tag."; },
    "Every 10 days.": function(d) { return "Alle 10 Tage."; },
    "And many more! ": function(d) { return "Und viele mehr!"; },
    "Sign up now!": function(d) { return "Jetzt anmelden!"; },
    Your: function(d) { return "Dein"; },
    "friend's": function(d) { return "Freund hat"; },
    "birthday!": function(d) { return "Geburtstag!"; },
    Pay: function(d) { return "Überweis"; },
    the: function(d) { return "die"; },
    "rent!": function(d) { return "Miete!"; },
    Bring: function(d) { return "Müll"; },
    "out the": function(d) { return "wird"; },
    "bin!": function(d) { return "abgeholt!"; },
    Cancel1: function(d) { return "Kündige"; },
    that: function(d) { return "das"; },
    "subscription!": function(d) { return "Abo!"; },
    Cool: function(d) { return "Tolle"; },
    exhibition: function(d) { return "Ausstellung"; },
    "starting!": function(d) { return "fängt an!"; },
    Renew: function(d) { return "Erneuer"; },
    magazine: function(d) { return "das Magazin-"; },
    Car: function(d) { return "Auto-"; },
    service: function(d) { return "Inspektion"; },
    "is due!": function(d) { return "ist fällig!"; },
    Check: function(d) { return "Prüf"; },
    bank: function(d) { return "Konto-"; },
    "statements!": function(d) { return "Auszüge!"; },
    Daylight: function(d) { return "Sommerzeit"; },
    "saving time": function(d) { return "fängt an!"; },
    "starts!": function(d) { return ""; },
    Turn: function(d) { return "Stell"; },
    "back the": function(d) { return "die Uhren"; },
    "clocks!": function(d) { return "zurück!"; },
    email: function(d) { return "Email an"; },
    "to a friend!": function(d) { return "einen Freund!"; },
    Buy: function(d) { return "Kauf"; },
    more: function(d) { return "mehr"; },
    "chocolate!": function(d) { return "Schokolade!"; },
    Research: function(d) { return "Erforsche"; },
    "the new": function(d) { return "die Neu-"; },
    "book!": function(d) { return "erscheinung!"; },
    Return: function(d) { return "Büchereibuch"; },
    library: function(d) { return "zurück!"; },
    "book!1": function(d) { return ""; },
    "By creating an account, you accept our Terms of Service and Privacy Policy.": function(d) { return "Mit dem Anlegen eines Benutzerkontos akzeptierst Du unsere Bedingungen und Privatsphärenerklärung"; }
  },
  gd: {
    Memomolecule: function(d) { return "TODO"; },
    "Internal Server Error": function(d) { return "Mearachd inntearnail, feuch a-rithist an déidh seo!"; },
    every: function(d) { return plural(d.NUMBER, 0, gd, { "0": "as aonais", one: "a h-uile", other: "gach" }); },
    daily: function(d) { return "làitheil"; },
    weekly: function(d) { return "seachdaineil"; },
    monthly: function(d) { return "mìosail"; },
    yearly: function(d) { return "bliadhnail"; },
    minute: function(d) { return plural(d.NUMBER, 0, gd, { "1": "mhionaid", "2": "mhionaid", other: "mionaidean" }); },
    hour: function(d) { return plural(d.NUMBER, 0, gd, { "1": "uair", "2": "uair", other: "uairean" }); },
    day: function(d) { return plural(d.NUMBER, 0, gd, { "1": "latha", "2": "latha", other: "latha" }); },
    week: function(d) { return plural(d.NUMBER, 0, gd, { "1": "sheachdain", "2": "sheachdain", other: "seachdainean" }); },
    month: function(d) { return plural(d.NUMBER, 0, gd, { "1": "mhìos", "2": "mhìos", other: "mìosan" }); },
    year: function(d) { return "bliadhna"; },
    Monday: function(d) { return "DiLuain"; },
    Tuesday: function(d) { return "DiMàirt"; },
    Wednesday: function(d) { return "DiCiadain"; },
    Thursday: function(d) { return "Diardaoin"; },
    Friday: function(d) { return "DihAoine"; },
    Saturday: function(d) { return "DiSathairne"; },
    Sunday: function(d) { return "DiDòmhnach"; },
    "only on these days of week": function(d) { return "air na làithean seo den t-seachdain a-mhàin"; },
    Recur: function(d) { return "tachair a-rithist"; },
    "An e-mail will be sent:": function(d) { return "Théid post-d a chuir:"; },
    Recurring: function(d) { return "Tillteach"; },
    on: function(d) { return ""; },
    "Must be a weekday on or before this day.": function(d) { return "Feumaidh gu bheil latha-seachdain a tha ann an latha seo no ann an latha roimhe seo."; },
    "of month": function(d) { return "dhen mhìos"; },
    "on first day of month": function(d) { return "a' chiad latha dhen mhìos"; },
    "on last day of month": function(d) { return "an latha mu dheireadh dhen mhìos"; },
    "on first": function(d) { return "a' chiad"; },
    "on last": function(d) { return "deireadh"; },
    "Send prenotifications": function(d) { return "Ro-chuir an cuimhne"; },
    add: function(d) { return "cuir ris"; },
    remove: function(d) { return "thoir air falbh"; },
    "Next email is due on": function(d) { return select(d.TEMPUS, { other: "Tha", past: "Bha" }) + " an ceann-ama airson an ath-phost dealain " + fmt.date(d.T, "gd", "full"); },
    "Next prenotification is due on": function(d) { return select(d.TEMPUS, { other: "Tha", past: "Bha" }) + " an ceann-ama airson an ath-ro-rabhas " + fmt.date(d.T, "gd", "full"); },
    "Next prenotification:": function(d) { return "Ath cheann-ama: " + fmt.date(d.T, "gd", "full"); },
    "in X days": function(d) { return "an ceann " + d.DAYS + " latha"; },
    "in X hours": function(d) { return "an ceann " + d.HOURS + " " + plural(d.HOURS, 0, gd, { one: "uair", other: "uairean" }); },
    "X days ago": function(d) { return "o chionn " + d.DAYS + " " + plural(d.DAYS, 0, gd, { "1": "latha", "2": "latha", other: "latha" }); },
    "X hours ago": function(d) { return "o chionn " + d.HOURS + " " + plural(d.HOURS, 0, gd, { one: "uair", other: "uairean" }); },
    "No days of week defined.": function(d) { return "Chan eil làithean na seachdain taghta."; },
    "All memo molecules are paused.": function(d) { return "Tha a h-uile memos air an stadadh."; },
    "There are currently no prenotifications.": function(d) { return "Chan eil cinn-ama ann airson an ath-ro-rabhas."; },
    "Upsert toast": function(d) { return select(d.METHOD, { added: "Memo ùr", deleted: "Sguabte às", updated: "Sàbhailte", other: "other" }) + " " + d.SUBJECT; },
    "Minute values must be multiples of": function(d) { return "Feumaidh luachan mionaid a bhith nan iomadan de " + d.NUMBER + "."; },
    "You are logged in as": function(d) { return "Tha thu air logadh a-steach mar " + d.USER + "."; },
    Signup: function(d) { return "Cruthaich cunntach"; },
    Login: function(d) { return "Log a-steach"; },
    Logout: function(d) { return "Log a-mach"; },
    "Register for a new account": function(d) { return "Cruthaich cunntas"; },
    "You will get an e-mail in the next minutes with a verification link.": function(d) { return "TODO"; },
    "Please check your e-mails and verify your account.": function(d) { return "TODO"; },
    "Add our e-mail address 'noreply@memomolecule.com' to your spam whitelist.": function(d) { return "TODO"; },
    "Check your e-mails for a password reset link": function(d) { return "TODO"; },
    "Back to the app": function(d) { return "TODO"; },
    Documentation: function(d) { return "TODO"; },
    "Privacy policy": function(d) { return "TODO"; },
    "Terms of Service": function(d) { return "Terms of Service"; },
    Contact: function(d) { return "TODO"; },
    "Log in to your account": function(d) { return "TODO"; },
    "All your memos": function(d) { return "TODO"; },
    "Unsent Memos": function(d) { return "TODO"; },
    "Sent Memos": function(d) { return "TODO"; },
    "Add a new memo": function(d) { return "TODO"; },
    "When should the memo be sent?": function(d) { return "TODO"; },
    Timezone: function(d) { return "TODO"; },
    "Edit this memo": function(d) { return "TODO"; },
    "Email subject": function(d) { return "TODO"; },
    "A short note": function(d) { return "TODO"; },
    "A longer description (optional)": function(d) { return "TODO"; },
    Save: function(d) { return "TODO"; },
    Cancel: function(d) { return "Sguab ás"; },
    Delete: function(d) { return "TODO"; },
    "Fetched all memos": function(d) { return "TODO"; },
    "Signed Up!": function(d) { return "TODO"; },
    "E-mail address": function(d) { return "TODO"; },
    "Memo saved successfully!": function(d) { return "TODO"; },
    "Your email address is verified.": function(d) { return "TODO"; },
    "You can now": function(d) { return "TODO"; },
    "log in": function(d) { return "TODO"; },
    "The verification failed. Make sure to use the latest email link.": function(d) { return "TODO"; },
    "Your email might already be verified and you can now": function(d) { return "TODO"; },
    "Need a": function(d) { return "TODO"; },
    "new verification email?": function(d) { return "TODO"; },
    "This email is already verified.": function(d) { return "TODO"; },
    Send: function(d) { return "TODO"; },
    "Request a new verification email": function(d) { return "TODO"; },
    "Password changed successfully!": function(d) { return "TODO"; },
    "Enter a new password": function(d) { return "TODO"; },
    "Manage User Settings": function(d) { return "TODO"; },
    and: function(d) { return "⁊"; },
    Language: function(d) { return "Cànan"; },
    "Select a Time Zone": function(d) { return "Wähle eine Zeitzone"; },
    "Time Zone changes will only affect newly created memo molecules!": function(d) { return "TODO"; },
    "All future emails will now use this language and date/time format!": function(d) { return "TODO"; },
    "Reload memos": function(d) { return "TODO"; },
    "Create Memo": function(d) { return "TODO"; },
    "All paused": function(d) { return "TODO"; },
    "If checked, no emails will be sent.": function(d) { return "TODO"; },
    "A time zone change will recalculate all Memos without an explicit time zone.": function(d) { return "TODO"; },
    "If you have recently signed up, have you verified your email?": function(d) { return "TODO"; },
    "Reset Password": function(d) { return "TODO"; },
    "There is no data to show.": function(d) { return "TODO"; },
    "There is no data to show. To add a new Memo molecule, press this button: ": function(d) { return "TODO"; },
    "No data fetched yet.": function(d) { return "TODO"; },
    mth1: function(d) { return d.DAY + " Faoi, " + d.YEAR; },
    mth2: function(d) { return d.DAY + " Gear, " + d.YEAR; },
    mth3: function(d) { return d.DAY + " Mar, " + d.YEAR; },
    mth4: function(d) { return d.DAY + " Gibl, " + d.YEAR; },
    mth5: function(d) { return d.DAY + " Cèit, " + d.YEAR; },
    mth6: function(d) { return d.DAY + " Òghm, " + d.YEAR; },
    mth7: function(d) { return d.DAY + " Iuch, " + d.YEAR; },
    mth8: function(d) { return d.DAY + " Lùn, " + d.YEAR; },
    mth9: function(d) { return d.DAY + " Sult, " + d.YEAR; },
    mth10: function(d) { return d.DAY + " Dàmh, " + d.YEAR; },
    mth11: function(d) { return d.DAY + " Samh, " + d.YEAR; },
    mth12: function(d) { return d.DAY + " Dùbh, " + d.YEAR; },
    dow0: function(d) { return "Did"; },
    dow1: function(d) { return "Dil"; },
    dow2: function(d) { return "Dim"; },
    dow3: function(d) { return "Dic"; },
    dow4: function(d) { return "Diar"; },
    dow5: function(d) { return "Dih"; },
    dow6: function(d) { return "Dis"; },
    dow7: function(d) { return "Did"; },
    Help: function(d) { return "Taisg"; },
    "Memo Molecule": function(d) { return "TODO"; },
    "The powerful rules let you set reminders like:": function(d) { return "TODO"; },
    "These little fellows come to your email and remind you!": function(d) { return "TODO"; },
    "Next Monday.": function(d) { return "TODO"; },
    "Later today.": function(d) { return "TODO"; },
    "Every year on the same day.": function(d) { return "TODO"; },
    "Every first day of the month.": function(d) { return "TODO"; },
    "Every last working day of the month.": function(d) { return "TODO"; },
    "Every week on a Thursday.": function(d) { return "TODO"; },
    "Every third Saturday in the month. ": function(d) { return "TODO"; },
    "Every two weeks.": function(d) { return "TODO"; },
    "Every Monday, Wednesday and Friday.": function(d) { return "TODO"; },
    "Every month on the 5th.": function(d) { return "TODO"; },
    "Every last Wednesday of the month.": function(d) { return "TODO"; },
    "On the last day of every second month.": function(d) { return "TODO"; },
    "Every 10 days.": function(d) { return "TODO"; },
    "And many more! ": function(d) { return "TODO"; },
    "Sign up now!": function(d) { return "TODO"; },
    Your: function(d) { return "TODO"; },
    "friend's": function(d) { return "TODO"; },
    "birthday!": function(d) { return "TODO"; },
    Pay: function(d) { return "TODO"; },
    the: function(d) { return "TODO"; },
    "rent!": function(d) { return "TODO"; },
    Bring: function(d) { return "TODO"; },
    "out the": function(d) { return "TODO"; },
    "bin!": function(d) { return "TODO"; },
    Cancel1: function(d) { return "TODO"; },
    that: function(d) { return "TODO"; },
    "subscription!": function(d) { return "TODO"; },
    Cool: function(d) { return "TODO"; },
    exhibition: function(d) { return "TODO"; },
    "starting!": function(d) { return "TODO"; },
    Renew: function(d) { return "TODO"; },
    magazine: function(d) { return "TODO"; },
    Car: function(d) { return "TODO"; },
    service: function(d) { return "TODO"; },
    "is due!": function(d) { return "TODO"; },
    Check: function(d) { return "TODO"; },
    bank: function(d) { return "TODO"; },
    "statements!": function(d) { return "TODO"; },
    Daylight: function(d) { return "TODO"; },
    "saving time": function(d) { return "TODO"; },
    "starts!": function(d) { return "TODO"; },
    Turn: function(d) { return "TODO"; },
    "back the": function(d) { return "TODO"; },
    "clocks!": function(d) { return "TODO"; },
    email: function(d) { return "TODO"; },
    "to friend!": function(d) { return "TODO"; },
    Buy: function(d) { return "TODO"; },
    more: function(d) { return "TODO"; },
    "chocolate!": function(d) { return "TODO"; },
    Research: function(d) { return "TODO"; },
    "the new": function(d) { return "TODO"; },
    "book!": function(d) { return "TODO"; },
    Return: function(d) { return "TODO"; },
    library: function(d) { return "TODO"; },
    "book!1": function(d) { return ""; },
    "By creating an account, you accept our Terms of Service and Privacy Policy.": function(d) { return "TODO"; }
  },
  fr: {
    Memomolecule: function(d) { return "Memomolecule"; },
    "Internal Server Error": function(d) { return "Erreur interne, veuillez réessayer ultérieurement."; },
    every: function(d) { return plural(d.NUMBER, 0, fr, { "0": "no", one: "chacque", other: "tous les" }); },
    daily: function(d) { return "tous les jours"; },
    weekly: function(d) { return "une fois par semaine"; },
    monthly: function(d) { return "une fois par mois"; },
    yearly: function(d) { return "une fois par an"; },
    minute: function(d) { return plural(d.NUMBER, 0, fr, { "1": "minute", other: "minutes" }); },
    hour: function(d) { return plural(d.NUMBER, 0, fr, { "1": "heure", other: "heures" }); },
    day: function(d) { return plural(d.NUMBER, 0, fr, { "1": "jour", other: "jours" }); },
    week: function(d) { return plural(d.NUMBER, 0, fr, { "1": "semaine", other: "semaines" }); },
    month: function(d) { return plural(d.NUMBER, 0, fr, { "1": "mois", other: "mois" }); },
    year: function(d) { return plural(d.NUMBER, 0, fr, { "1": "an", other: "ans" }); },
    "Next email is due on": function(d) { return "Next email " + select(d.TEMPUS, { other: "is", past: "was" }) + " due on " + fmt.date(d.T, "fr", "full"); },
    "Next prenotification is due on": function(d) { return "Next prenotification " + select(d.TEMPUS, { other: "is", past: "was" }) + " due on " + fmt.date(d.T, "fr", "full"); },
    "Next prenotification:": function(d) { return "Next prenotification: " + fmt.date(d.T, "fr", "full"); },
    Monday: function(d) { return "Lundi"; },
    Tuesday: function(d) { return "Mardi"; },
    Wednesday: function(d) { return "Mercredi"; },
    Thursday: function(d) { return "Jeudi"; },
    Friday: function(d) { return "Vendredi"; },
    Saturday: function(d) { return "Samedi"; },
    Sunday: function(d) { return "Dimanche"; },
    "only on these days of week": function(d) { return "uniquement ces jours de la semaine"; },
    Recur: function(d) { return "Répétition"; },
    "The e-mail subject will be:": function(d) { return "Le sujet du courriel sera:"; },
    "An e-mail will be sent:": function(d) { return "Un courriel sera envoyé:"; },
    "The Memomolecule will repeat automatically:": function(d) { return "The Memomolecule will repeat automatically:"; },
    "Invalid Date Format": function(d) { return "Format de date invalide"; },
    "Click \"+\" to add one more line.": function(d) { return "Cliquez sur « + » pour ajouter une ligne."; },
    "The recurrence should be:": function(d) { return "The recurrence should be:"; },
    "With this interval:": function(d) { return "With this interval:"; },
    Recurring: function(d) { return "Recurring"; },
    "Activate a Recurrence": function(d) { return "Activer une répétition"; },
    "You can add intervals by typing them as numbers into the field.": function(d) { return "You can add intervals by typing them as numbers into the field."; },
    "When:": function(d) { return "Quand:"; },
    "There are multiple options for this day:": function(d) { return "There are multiple options for this day:"; },
    on: function(d) { return ""; },
    "Must be a weekday on or before this day.": function(d) { return "Must be a weekday on or before this day."; },
    "of month": function(d) { return "du mois"; },
    "on first day of month": function(d) { return "le premier jour du mois"; },
    "on last day of month": function(d) { return "le dernier jour du mois"; },
    "on first": function(d) { return "le premier"; },
    "on last": function(d) { return "le dernier"; },
    "Activate Prenotifications": function(d) { return "Activer notification préalable"; },
    "Prenotifications are sent at fixed intervals before the actual notification time.": function(d) { return "Prenotifications are sent at fixed intervals before the actual notification time."; },
    add: function(d) { return "ajouter"; },
    remove: function(d) { return "enlever"; },
    "in X days": function(d) { return "en " + d.DAYS + " " + plural(d.DAYS, 0, fr, { one: "jour", other: "jours" }); },
    "in X hours": function(d) { return "en " + d.HOURS + " " + plural(d.HOURS, 0, fr, { one: "heure", other: "heures" }); },
    "X days ago": function(d) { return "avant " + d.DAYS + " " + plural(d.DAYS, 0, fr, { one: "jour", other: "jours" }); },
    "X hours ago": function(d) { return "avant " + d.HOURS + " " + plural(d.HOURS, 0, fr, { one: "heure", other: "heures" }); },
    "No days of week defined.": function(d) { return "No days of week defined."; },
    "All memo molecules are paused.": function(d) { return "Tous les mémos sont en pause."; },
    "There are currently no prenotifications.": function(d) { return "Il n'ya pas des notifications préalables actuellement."; },
    "Upsert toast": function(d) { return select(d.METHOD, { added: "Nouveau mémo", deleted: "Enlevé", updated: "Enregistré", other: "autre" }) + " " + d.SUBJECT; },
    "Minute values must be multiples of": function(d) { return "Minute values must be multiples of " + d.NUMBER + "."; },
    "You are logged in as": function(d) { return "You are logged in as " + d.USER + "."; },
    Signup: function(d) { return "Créer un compte"; },
    Login: function(d) { return "Se connecter"; },
    Logout: function(d) { return "Se déconnecter"; },
    "Register for a new account": function(d) { return "Régistrer pour un compte nouveau."; },
    "You will get an e-mail in the next minutes with a verification link.": function(d) { return "You will get an e-mail in the next minutes with a verification link."; },
    "Please check your e-mails and verify your account.": function(d) { return "Please check your e-mails and verify your account."; },
    "Add our e-mail address 'noreply@memomolecule.com' to your spam whitelist.": function(d) { return "Add our e-mail address 'noreply@memomolecule.com' to your spam whitelist."; },
    "Check your e-mails for a password reset link": function(d) { return "Check your e-mails for a password reset link"; },
    "Back to the app": function(d) { return "Retour à l'application"; },
    "Log in to your account": function(d) { return "Log in to your account"; },
    "All your memos": function(d) { return "Tous vos mémos"; },
    "Unsent Memos": function(d) { return "Unsent Memos"; },
    "Sent Memos": function(d) { return "Sent Memos"; },
    "Add a new memo": function(d) { return "Ajouter un nouveau mémo"; },
    "When should the memo be sent?": function(d) { return "When should the memo start?"; },
    Timezone: function(d) { return "Fuseau horaire"; },
    Unsent: function(d) { return "Unsent"; },
    Sent: function(d) { return "Sent"; },
    "Edit this memo": function(d) { return "Change ce mémo"; },
    "Email subject": function(d) { return "Sujet du courriel"; },
    "A short note": function(d) { return "Un petit mot"; },
    "A longer description (optional)": function(d) { return "Une description plus longue (facultative)"; },
    Save: function(d) { return "Enregistrer"; },
    Cancel: function(d) { return "Annuler"; },
    Delete: function(d) { return "Supprimer"; },
    "Fetched all memos": function(d) { return "Fetched all memos"; },
    "Signed Up!": function(d) { return "Signed Up!"; },
    "E-mail address": function(d) { return "E-mail address"; },
    "Memo saved successfully!": function(d) { return "Memo saved successfully!"; },
    "Your email address is verified.": function(d) { return "Your email address is verified."; },
    "You can now": function(d) { return "Tu peux maintenant"; },
    "log in": function(d) { return "se connecter"; },
    "The verification failed. Make sure to use the latest email link.": function(d) { return "The verification failed. Make sure to use the latest email link."; },
    "Your email might already be verified and you can now": function(d) { return "Your email might already be verified and you can now"; },
    "Need a": function(d) { return "Est-ce que tu veut un"; },
    "new verification email?": function(d) { return "courriel de vérification nouveau?"; },
    "This email is already verified.": function(d) { return "This email is already verified."; },
    Send: function(d) { return "Send"; },
    "Request a new verification email": function(d) { return "Request a new verification email"; },
    "Password changed successfully!": function(d) { return "Password changed successfully!"; },
    "Enter a new password": function(d) { return "Enter a new password"; },
    "Manage User Settings": function(d) { return "Manage User Settings"; },
    and: function(d) { return "et"; },
    Language: function(d) { return "Langue"; },
    "Select a Time Zone": function(d) { return "Sélectioner un fuseaur horaire"; },
    "A different timezone from user settings can be chosen.": function(d) { return "A different timezone from user settings can be chosen."; },
    "Time Zone changes will only affect newly created memo molecules!": function(d) { return "Time Zone changes will only affect newly created memo molecules!"; },
    "All future emails will now use this language and date/time format!": function(d) { return "All future emails will now use this language and date/time format!"; },
    "Reload memos": function(d) { return "Reload memos"; },
    "Create Memo": function(d) { return "Créer un mémo"; },
    "All paused": function(d) { return "Tous en pause"; },
    "If checked, no emails will be sent.": function(d) { return "If checked, no emails will be sent."; },
    "Hide helping hints": function(d) { return "Hide helping hints"; },
    "If checked, no on-screen hint labels will be shown.": function(d) { return "If checked, no on-screen hint labels will be shown."; },
    "Choose a different time zone": function(d) { return "Choisisser un autre fuseau horaire"; },
    "A time zone change will recalculate all Memos without an explicit time zone.": function(d) { return "A time zone change will recalculate all Memos without an explicit time zone."; },
    "If you have recently signed up, have you verified your email?": function(d) { return "If you have recently signed up, have you verified your email?"; },
    "Reset Password": function(d) { return "Reset Password"; },
    "There is no data to show. To add a new Memo molecule, press this button: ": function(d) { return "There is no data to show. To add a new Memo molecule, press this button: "; },
    "There is no data to show.": function(d) { return "There is no data to show."; },
    "No data fetched yet.": function(d) { return "No data fetched yet."; },
    mth1: function(d) { return d.DAY + " janv., " + d.YEAR; },
    mth2: function(d) { return d.DAY + " févr., " + d.YEAR; },
    mth3: function(d) { return d.DAY + " mars, " + d.YEAR; },
    mth4: function(d) { return d.DAY + " avr., " + d.YEAR; },
    mth5: function(d) { return d.DAY + " mai, " + d.YEAR; },
    mth6: function(d) { return d.DAY + " juin, " + d.YEAR; },
    mth7: function(d) { return d.DAY + " juil., " + d.YEAR; },
    mth8: function(d) { return d.DAY + " août, " + d.YEAR; },
    mth9: function(d) { return d.DAY + " sept., " + d.YEAR; },
    mth10: function(d) { return d.DAY + " oct., " + d.YEAR; },
    mth11: function(d) { return d.DAY + " nov., " + d.YEAR; },
    mth12: function(d) { return d.DAY + " déc, " + d.YEAR; },
    dow0: function(d) { return "Dimanche"; },
    dow1: function(d) { return "Lundi"; },
    dow2: function(d) { return "Mardi"; },
    dow3: function(d) { return "Mercredi"; },
    dow4: function(d) { return "Jeudi"; },
    dow5: function(d) { return "Vendredi"; },
    dow6: function(d) { return "Samedi"; },
    dow7: function(d) { return "Dimanche"; },
    Help: function(d) { return "Assistance"; },
    "Up to x additional e-mail can be sent before:": function(d) { return "Up to " + d.NUMBER + " additional e-mails can be sent before:"; },
    Documentation: function(d) { return "Documentation"; },
    "Privacy policy": function(d) { return "Privacy policy"; },
    "Terms of Service": function(d) { return "Terms of Service"; },
    Contact: function(d) { return "Contact"; },
    "Memo Molecule": function(d) { return "Memo Molecule"; },
    "The powerful rules let you set reminders like:": function(d) { return "The powerful rules let you set reminders like:"; },
    "These little fellows come to your email and remind you!": function(d) { return "These little fellows come to your email and remind you!"; },
    "Next Monday.": function(d) { return "Lundi prochain."; },
    "Later today.": function(d) { return "Plus tard aujourd'hui"; },
    "Every year on the same day.": function(d) { return "Every year on the same day."; },
    "Every first day of the month.": function(d) { return "Every first day of the month."; },
    "Every last working day of the month.": function(d) { return "Every last working day of the month."; },
    "Every week on a Thursday.": function(d) { return "Every week on a Thursday."; },
    "Every third Saturday in the month. ": function(d) { return "Every third Saturday in the month. "; },
    "Every two weeks.": function(d) { return "Every two weeks."; },
    "Every Monday, Wednesday and Friday.": function(d) { return "Every Monday, Wednesday and Friday."; },
    "Every month on the 5th.": function(d) { return "Every month on the 5th."; },
    "Every last Wednesday of the month.": function(d) { return "Every last Wednesday of the month."; },
    "On the last day of every second month.": function(d) { return "On the last day of every second month."; },
    "Every 10 days.": function(d) { return "Every 10 days."; },
    "And many more! ": function(d) { return "And many more! "; },
    "Sign up now!": function(d) { return "Sign up now!"; },
    Your: function(d) { return "Your"; },
    "friend's": function(d) { return "friend's"; },
    "birthday!": function(d) { return "birthday!"; },
    Pay: function(d) { return "Pay"; },
    the: function(d) { return "the"; },
    "rent!": function(d) { return "rent!"; },
    Bring: function(d) { return "Bring"; },
    "out the": function(d) { return "out the"; },
    "bin!": function(d) { return "bin!"; },
    Cancel1: function(d) { return "Annuler"; },
    that: function(d) { return "that"; },
    "subscription!": function(d) { return "subscription!"; },
    Cool: function(d) { return "Cool"; },
    exhibition: function(d) { return "exhibition"; },
    "starting!": function(d) { return "starting!"; },
    Renew: function(d) { return "Renew"; },
    magazine: function(d) { return "magazine"; },
    Car: function(d) { return "Car"; },
    service: function(d) { return "service"; },
    "is due!": function(d) { return "is due!"; },
    Check: function(d) { return "Check"; },
    bank: function(d) { return "bank"; },
    "statements!": function(d) { return "statements!"; },
    Daylight: function(d) { return "Daylight"; },
    "saving time": function(d) { return "saving time"; },
    "starts!": function(d) { return "starts!"; },
    Turn: function(d) { return "Turn"; },
    "back the": function(d) { return "back the"; },
    "clocks!": function(d) { return "clocks!"; },
    email: function(d) { return "email"; },
    "to friend!": function(d) { return "to friend!"; },
    Buy: function(d) { return "Buy"; },
    more: function(d) { return "more"; },
    "chocolate!": function(d) { return "chocolate!"; },
    Research: function(d) { return "Research"; },
    "the new": function(d) { return "the new"; },
    "book!": function(d) { return "book!"; },
    Return: function(d) { return "Return"; },
    library: function(d) { return "library"; },
    "book!1": function(d) { return "book!"; },
    "By creating an account, you accept our Terms of Service and Privacy Policy.": function(d) { return "By creating an account, you accept our Terms of Service and Privacy Policy."; }
  },
  en: {
    Memomolecule: function(d) { return "Memomolecule"; },
    "Internal Server Error": function(d) { return "Internal error, try again later!"; },
    every: function(d) { return plural(d.NUMBER, 0, en, { "0": "no", one: "every", other: "every" }); },
    daily: function(d) { return "daily"; },
    weekly: function(d) { return "weekly"; },
    monthly: function(d) { return "monthly"; },
    yearly: function(d) { return "yearly"; },
    minute: function(d) { return plural(d.NUMBER, 0, en, { "1": "minute", other: "minutes" }); },
    hour: function(d) { return plural(d.NUMBER, 0, en, { "1": "hour", other: "hours" }); },
    day: function(d) { return plural(d.NUMBER, 0, en, { "1": "day", other: "days" }); },
    week: function(d) { return plural(d.NUMBER, 0, en, { "1": "week", other: "weeks" }); },
    month: function(d) { return plural(d.NUMBER, 0, en, { "1": "month", other: "months" }); },
    year: function(d) { return plural(d.NUMBER, 0, en, { "1": "year", other: "years" }); },
    "Next email is due on": function(d) { return "Next email " + select(d.TEMPUS, { other: "is", past: "was" }) + " due on " + fmt.date(d.T, "en", "full"); },
    "Next prenotification is due on": function(d) { return "Next prenotification " + select(d.TEMPUS, { other: "is", past: "was" }) + " due on " + fmt.date(d.T, "en", "full"); },
    "Next prenotification:": function(d) { return "Next prenotification: " + fmt.date(d.T, "en", "full"); },
    Monday: function(d) { return "Monday"; },
    Tuesday: function(d) { return "Tuesday"; },
    Wednesday: function(d) { return "Wednesday"; },
    Thursday: function(d) { return "Thursday"; },
    Friday: function(d) { return "Friday"; },
    Saturday: function(d) { return "Saturday"; },
    Sunday: function(d) { return "Sunday"; },
    "only on these days of week": function(d) { return "only on these days of the week"; },
    Recur: function(d) { return "Recur"; },
    "The e-mail subject will be:": function(d) { return "The e-mail subject will be:"; },
    "An e-mail will be sent:": function(d) { return "An e-mail will be sent:"; },
    "The Memomolecule will repeat automatically:": function(d) { return "The Memomolecule will repeat automatically:"; },
    "Invalid Date Format": function(d) { return "Invalid Date Format"; },
    "Click \"+\" to add one more line.": function(d) { return "Click \"+\" to add one more line."; },
    "The recurrence should be:": function(d) { return "The recurrence should be:"; },
    "With this interval:": function(d) { return "With this interval:"; },
    Recurring: function(d) { return "Recurring"; },
    "Activate a Recurrence": function(d) { return "Activate a Recurrence"; },
    "You can add intervals by typing them as numbers into the field.": function(d) { return "You can add intervals by typing them as numbers into the field."; },
    "When:": function(d) { return "When:"; },
    "There are multiple options for this day:": function(d) { return "There are multiple options for this day:"; },
    on: function(d) { return "on:"; },
    "Must be a weekday on or before this day.": function(d) { return "Must be a weekday on or before this day."; },
    "of month": function(d) { return "of month"; },
    "on first day of month": function(d) { return "on first day of month"; },
    "on last day of month": function(d) { return "on last day of month"; },
    "on first": function(d) { return "on first"; },
    "on last": function(d) { return "on last"; },
    "Activate Prenotifications": function(d) { return "Activate Prenotifications"; },
    "Prenotifications are sent at fixed intervals before the actual notification time.": function(d) { return "Prenotifications are sent at fixed intervals before the actual notification time."; },
    add: function(d) { return "add"; },
    remove: function(d) { return "remove"; },
    "in X days": function(d) { return "in " + d.DAYS + " " + plural(d.DAYS, 0, en, { one: "day", other: "days" }); },
    "in X hours": function(d) { return "in " + d.HOURS + " " + plural(d.HOURS, 0, en, { one: "hour", other: "hours" }); },
    "X days ago": function(d) { return d.DAYS + " " + plural(d.DAYS, 0, en, { one: "day", other: "days" }) + " ago"; },
    "X hours ago": function(d) { return d.HOURS + " " + plural(d.HOURS, 0, en, { one: "hour", other: "hours" }) + " ago"; },
    "No days of week defined.": function(d) { return "No days of week defined."; },
    "All memo molecules are paused.": function(d) { return "All memo molecules are paused."; },
    "There are currently no prenotifications.": function(d) { return "There are currently no prenotifications."; },
    "Upsert toast": function(d) { return select(d.METHOD, { Added: "New Memo", deleted: "Deleted", updated: "Saved", other: "other" }) + " " + d.SUBJECT; },
    "Minute values must be multiples of": function(d) { return "Minute values must be multiples of " + d.NUMBER + "."; },
    "You are logged in as": function(d) { return "You are logged in as " + d.USER + "."; },
    Signup: function(d) { return "Signup"; },
    Login: function(d) { return "Login"; },
    Logout: function(d) { return "Logout"; },
    "Register for a new account": function(d) { return "Register for a new account"; },
    "You will get an e-mail in the next minutes with a verification link.": function(d) { return "You will get an e-mail in the next minutes with a verification link."; },
    "Please check your e-mails and verify your account.": function(d) { return "Please check your e-mails and verify your account."; },
    "Add our e-mail address 'noreply@memomolecule.com' to your spam whitelist.": function(d) { return "Add our e-mail address 'noreply@memomolecule.com' to your spam whitelist."; },
    "Check your e-mails for a password reset link": function(d) { return "Check your e-mails for a password reset link"; },
    "Back to the app": function(d) { return "Back to the app"; },
    "Log in to your account": function(d) { return "Log in to your account"; },
    "All your memos": function(d) { return "All your memos"; },
    "Unsent Memos": function(d) { return "Unsent Memos"; },
    "Sent Memos": function(d) { return "Sent Memos"; },
    "Add a new memo": function(d) { return "Add a new memo"; },
    "When should the memo be sent?": function(d) { return "When should the memo start?"; },
    Timezone: function(d) { return "Time Zone"; },
    Unsent: function(d) { return "Unsent"; },
    Sent: function(d) { return "Sent"; },
    "Edit this memo": function(d) { return "Change this memo"; },
    "Email subject": function(d) { return "Email subject"; },
    "A short note": function(d) { return "A short note"; },
    "A longer description (optional)": function(d) { return "A longer description (optional)"; },
    Save: function(d) { return "Save"; },
    Cancel: function(d) { return "Cancel"; },
    Delete: function(d) { return "Delete"; },
    "Fetched all memos": function(d) { return "Fetched all memos"; },
    "Signed Up!": function(d) { return "Signed Up!"; },
    "E-mail address": function(d) { return "E-mail address"; },
    "Memo saved successfully!": function(d) { return "Memo saved successfully!"; },
    "Your email address is verified.": function(d) { return "Your email address is verified."; },
    "You can now": function(d) { return "You can now"; },
    "log in": function(d) { return "log in"; },
    "The verification failed. Make sure to use the latest email link.": function(d) { return "The verification failed. Make sure to use the latest email link."; },
    "Your email might already be verified and you can now": function(d) { return "Your email might already be verified and you can now"; },
    "Need a": function(d) { return "Need a"; },
    "new verification email?": function(d) { return "new verification email?"; },
    "This email is already verified.": function(d) { return "This email is already verified."; },
    Send: function(d) { return "Send"; },
    "Request a new verification email": function(d) { return "Request a new verification email"; },
    "Password changed successfully!": function(d) { return "Password changed successfully!"; },
    "Enter a new password": function(d) { return "Enter a new password"; },
    "Manage User Settings": function(d) { return "Manage User Settings"; },
    and: function(d) { return "and"; },
    Language: function(d) { return "Language"; },
    "Select a Time Zone": function(d) { return "Select a Time Zone"; },
    "A different timezone from user settings can be chosen.": function(d) { return "A different timezone from user settings can be chosen."; },
    "Time Zone changes will only affect newly created memo molecules!": function(d) { return "Time Zone changes will only affect newly created memo molecules!"; },
    "All future emails will now use this language and date/time format!": function(d) { return "All future emails will now use this language and date/time format!"; },
    "Reload memos": function(d) { return "Reload memos"; },
    "Create Memo": function(d) { return "Create Memo"; },
    "All paused": function(d) { return "All paused"; },
    "If checked, no emails will be sent.": function(d) { return "If checked, no emails will be sent."; },
    "Hide helping hints": function(d) { return "Hide helping hints"; },
    "If checked, no on-screen hint labels will be shown.": function(d) { return "If checked, no on-screen hint labels will be shown."; },
    "Choose a different time zone": function(d) { return "Choose a different time zone"; },
    "A time zone change will recalculate all Memos without an explicit time zone.": function(d) { return "A time zone change will recalculate all Memos without an explicit time zone."; },
    "If you have recently signed up, have you verified your email?": function(d) { return "If you have recently signed up, have you verified your email?"; },
    "Reset Password": function(d) { return "Reset Password"; },
    "There is no data to show. To add a new Memo molecule, press this button: ": function(d) { return "There is no data to show. To add a new Memo molecule, press this button: "; },
    "There is no data to show.": function(d) { return "There is no data to show."; },
    "No data fetched yet.": function(d) { return "No data fetched yet."; },
    mth1: function(d) { return d.DAY + " Jan, " + d.YEAR; },
    mth2: function(d) { return d.DAY + " Feb, " + d.YEAR; },
    mth3: function(d) { return d.DAY + " Mar, " + d.YEAR; },
    mth4: function(d) { return d.DAY + " Apr, " + d.YEAR; },
    mth5: function(d) { return d.DAY + " May, " + d.YEAR; },
    mth6: function(d) { return d.DAY + " Jun, " + d.YEAR; },
    mth7: function(d) { return d.DAY + " Jul, " + d.YEAR; },
    mth8: function(d) { return d.DAY + " Aug, " + d.YEAR; },
    mth9: function(d) { return d.DAY + " Sep, " + d.YEAR; },
    mth10: function(d) { return d.DAY + " Oct, " + d.YEAR; },
    mth11: function(d) { return d.DAY + " Nov, " + d.YEAR; },
    mth12: function(d) { return d.DAY + " Dec, " + d.YEAR; },
    dow0: function(d) { return "Sunday"; },
    dow1: function(d) { return "Monday"; },
    dow2: function(d) { return "Tuesday"; },
    dow3: function(d) { return "Wednesday"; },
    dow4: function(d) { return "Thursday"; },
    dow5: function(d) { return "Friday"; },
    dow6: function(d) { return "Saturday"; },
    dow7: function(d) { return "Sunday"; },
    Help: function(d) { return "Help"; },
    "Up to x additional e-mail can be sent before:": function(d) { return "Up to " + d.NUMBER + " additional e-mails can be sent before:"; },
    Documentation: function(d) { return "Documentation"; },
    "Privacy policy": function(d) { return "Privacy policy"; },
    "Terms of Service": function(d) { return "Terms of Service"; },
    Contact: function(d) { return "Contact"; },
    "Memo Molecule": function(d) { return "Memo Molecule"; },
    "The powerful rules let you set reminders like:": function(d) { return "The powerful rules let you set reminders like:"; },
    "These little fellows come to your email and remind you!": function(d) { return "These little fellows come to your email and remind you!"; },
    "Next Monday.": function(d) { return "Next Monday."; },
    "Later today.": function(d) { return "Later today."; },
    "Every year on the same day.": function(d) { return "Every year on the same day."; },
    "Every first day of the month.": function(d) { return "Every first day of the month."; },
    "Every last working day of the month.": function(d) { return "Every last working day of the month."; },
    "Every week on a Thursday.": function(d) { return "Every week on a Thursday."; },
    "Every third Saturday in the month. ": function(d) { return "Every third Saturday in the month. "; },
    "Every two weeks.": function(d) { return "Every two weeks."; },
    "Every Monday, Wednesday and Friday.": function(d) { return "Every Monday, Wednesday and Friday."; },
    "Every month on the 5th.": function(d) { return "Every month on the 5th."; },
    "Every last Wednesday of the month.": function(d) { return "Every last Wednesday of the month."; },
    "On the last day of every second month.": function(d) { return "On the last day of every second month."; },
    "Every 10 days.": function(d) { return "Every 10 days."; },
    "And many more! ": function(d) { return "And many more! "; },
    "Sign up now!": function(d) { return "Sign up now!"; },
    Your: function(d) { return "Your"; },
    "friend's": function(d) { return "friend's"; },
    "birthday!": function(d) { return "birthday!"; },
    Pay: function(d) { return "Pay"; },
    the: function(d) { return "the"; },
    "rent!": function(d) { return "rent!"; },
    Bring: function(d) { return "Bring"; },
    "out the": function(d) { return "out the"; },
    "bin!": function(d) { return "bin!"; },
    Cancel1: function(d) { return "Cancel"; },
    that: function(d) { return "that"; },
    "subscription!": function(d) { return "subscription!"; },
    Cool: function(d) { return "Cool"; },
    exhibition: function(d) { return "exhibition"; },
    "starting!": function(d) { return "starting!"; },
    Renew: function(d) { return "Renew"; },
    magazine: function(d) { return "magazine"; },
    Car: function(d) { return "Car"; },
    service: function(d) { return "service"; },
    "is due!": function(d) { return "is due!"; },
    Check: function(d) { return "Check"; },
    bank: function(d) { return "bank"; },
    "statements!": function(d) { return "statements!"; },
    Daylight: function(d) { return "Daylight"; },
    "saving time": function(d) { return "saving time"; },
    "starts!": function(d) { return "starts!"; },
    Turn: function(d) { return "Turn"; },
    "back the": function(d) { return "back the"; },
    "clocks!": function(d) { return "clocks!"; },
    email: function(d) { return "email"; },
    "to friend!": function(d) { return "to friend!"; },
    Buy: function(d) { return "Buy"; },
    more: function(d) { return "more"; },
    "chocolate!": function(d) { return "chocolate!"; },
    Research: function(d) { return "Research"; },
    "the new": function(d) { return "the new"; },
    "book!": function(d) { return "book!"; },
    Return: function(d) { return "Return"; },
    library: function(d) { return "library"; },
    "book!1": function(d) { return "book!"; },
    "By creating an account, you accept our Terms of Service and Privacy Policy.": function(d) { return "By creating an account, you accept our Terms of Service and Privacy Policy."; }
  }
}
