/*    
 * This file contains the actions, action creators and reducers for Memo Form
 */

import {
  DOWMAP,
  RT_DEFAULT,
  RT_DAILY,
  RT_WEEKLY,
  RT_MONTHLY,
  RT_YEARLY,
  MNTH_DEFAULT
} from "./constants";

export const POST_MEMO = 'POST_MEMO';
export const POST_MEMO_SUCCESS = 'POST_MEMO_SUCCESS';
export const POST_MEMO_FAILURE = 'POST_MEMO_FAILURE';
export const SET_MEMO = 'SET_MEMO';
export const MODIFY_MEMO = 'MODIFY_MEMO';
export const MODIFY_RECURRENCE_OPTION = 'MODIFY_RECURRENCE_OPTION';
export const CREATE_NEW_MEMO = 'CREATE_NEW_MEMO';
export const MARK_DELETE_MEMO = 'MARK_DELETE_MEMO';
export const DELETE_MEMO = 'DELETE_MEMO';
export const DELETE_MEMO_SUCCESS = 'DELETE_MEMO_SUCCESS';
export const DELETE_MEMO_FAILURE = 'DELETE_MEMO_FAILURE';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const OPEN_MANAGE_MODAL = 'OPEN_MANAGE_MODAL';
export const CLOSE_MANAGE_MODAL = 'CLOSE_MANAGE_MODAL';

export const setMemo = memo => ({
  type: SET_MEMO,
  memo
});

export const openManageModal = () => ({
  type: OPEN_MANAGE_MODAL
})

export const closeManageModal = () => ({
  type: CLOSE_MANAGE_MODAL
})

export const modifyMemo = field => ({
  type: MODIFY_MEMO,
  field
});

export const modifyRecurrenceOption = opt => ({
  type: MODIFY_RECURRENCE_OPTION,
  opt
});

export const createNewMemo = () => ({
  type: CREATE_NEW_MEMO
});

export const deleteMemo = (id, personId, memo) => ({
  type: DELETE_MEMO,
  id,
  personId,
  memo
});

export const markAsDeleted = (memo) => ({
  type: MARK_DELETE_MEMO,
  memo
});

export const postMemo = memo => ({
  type: POST_MEMO,
  memo
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS
});

const emptyMemo = {
  when: new Date(),
  time: "00:00",
  subject: "",
  message: "",
  recurOptions: {
    type: RT_DEFAULT,
    daysOfWeek: Object.values(DOWMAP),
    interval: 1,
    monthOption: MNTH_DEFAULT
  }
};

const initialState = {
  id: undefined,
  modified: false,
  isPosting: false,
  memo: emptyMemo,
  apiError: {},
  manageModalOpen: false
};

const reduceRecurOptions = (oldOpt, newOpt) => {
  if (oldOpt && newOpt && newOpt.type && oldOpt.type !== newOpt.type) {
    switch (newOpt.type) {
      case RT_DAILY:
        return {
          daysOfWeek: Object.values(DOWMAP),
          interval: 1,
          ...newOpt
        };
      case RT_WEEKLY:
        return {
          interval: 1,
          ...newOpt
        };
      case RT_MONTHLY:
        return {
          daysOfWeek: Object.values(DOWMAP),
          monthOption: MNTH_DEFAULT,
          interval: 1,
          ...newOpt
        };
      case RT_YEARLY:
        return {
          interval: 1,
          ...newOpt
        };

      default:
        return { ...newOpt };
    }
  } else {
    return { ...oldOpt, ...newOpt };
  }
};

const memoFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_MEMO:
    case DELETE_MEMO_SUCCESS:
      return initialState;

    case POST_MEMO:
      return {
        ...state,
        isPosting: true,
        success: undefined,
        apiError: {}
      };
    case POST_MEMO_SUCCESS:
      return {
        ...state,
        isPosting: false,
        success: true,
        apiError: {}
      };
    case POST_MEMO_FAILURE:
      return {
        ...state,
        isPosting: false,
        success: false,
        apiError: action.apiError
      };
    case SET_MEMO:
      return {
        ...state,
        memo: action.memo,
        modified: false
      };
    case MODIFY_MEMO:
      return {
        ...state,
        memo: { ...state.memo, ...action.field },
        modified: true
      };
    case MODIFY_RECURRENCE_OPTION:
      return {
        ...state,
        memo: {
          ...state.memo,
          recurOptions: reduceRecurOptions(state.memo.recurOptions, action.opt)
        },
        modified: true
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        apiError: {},
        success: undefined
      };

    case OPEN_MANAGE_MODAL:
      return {
        ...state,
        manageModalOpen: true,
        apiError: {},
        success: undefined,
      };

    case CLOSE_MANAGE_MODAL:
      return {
        ...state,
        manageModalOpen: false,
        apiError: {},
        success: undefined,
      };

    case MARK_DELETE_MEMO: 
      return {
        ...state,
        isPosting: true
      };

    default:
      return state;
  }
};

export default memoFormReducer;
