import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInHours from "date-fns/differenceInHours";
import parseISO from "date-fns/parseISO";
import { createSelector } from "reselect";
import msgData from "lang/messages";
import Messages from "messageformat/messages";
import { select } from "redux-saga/effects";
import { toast } from "react-toastify";

export const isPositiveInt = n => Number.isInteger(Number(n)) && n > 0;

export const isEmptyArr = a => !a || (a && Array.isArray(a) && a.length === 0);

// used in array filter method, expects a sorted array and returns a copy that
// contains only unique values
export const uniqueArray = (v, i, arr) =>
  i === 0 || (i > 0 && v !== arr[i - 1]);

export const ascending = (a, b) => a - b;

export const isMaxArrLength = (a, max) => !isEmptyArr(a) && a.length < max;

// "due" date messages:

export const dueDateMessage = (d, messages, text) => {
  const dateDueDelta = d ? differenceInCalendarDays(parseISO(d), new Date()) : 0;

  const hoursDueDelta = d ? differenceInHours(parseISO(d), new Date()) : 0;

  const deltaTempus = hoursDueDelta < 0 || dateDueDelta < 0 ? "past" : "fut";

  const deltaHours =
    hoursDueDelta !== undefined && hoursDueDelta >= 0
      ? messages.get("in X hours", { HOURS: hoursDueDelta })
      : messages.get("X hours ago", {
          HOURS: Math.abs(hoursDueDelta)
        });

  const delta =
    dateDueDelta > 1
      ? messages.get("in X days", { DAYS: dateDueDelta })
      : dateDueDelta < 0
      ? messages.get("X days ago", {
          DAYS: Math.abs(dateDueDelta)
        })
      : deltaHours;

  return `${messages.get(text, {
    TEMPUS: deltaTempus,
    T: d
  })} 
    ${delta}.
    `;
};

// returns a shortened string that contains the ellipsis in the middle
// i.e. the beginning and end of string are returned: "one very very long string" becomes "one very...string"
export const elliptic = (string, len = 10) =>
  string && string.length > len
    ? `${string.substring(0, len / 2)}…${string.substring(
        string.length - len / 2,
        string.length
      )}`
    : string;

// string ellipsis that occurs only on word breaks with at least "len"
// characters plus any characters until the next word boundary
// i.e. "Mostly cloudy with a light chance of wintry rain" becomes "Mostly cloudy with …"
export const wordEllipsis = (s, len = s.len) => {
  const re = new RegExp("^(.{" + len + "}[^s]*).*");
  const newStr = s.replace(re, "$1");
  return s.length > len + 3 ? `${newStr} …` : s;
};

export const getMessages = createSelector(
  state => state.uistate,
  uistate => new Messages(msgData, uistate.language)
);

export function* showToast(message, params, className) {
  if (!message) return;
  const language = yield select(state => state.uistate.language);
  const i18n = new Messages(msgData, language);
  const msg = i18n.get(message, params);
  if(className)
    toast(msg, { className });
  else 
    toast.success(msg);
}

export const parseQuery = queryString => {
  const query = {};
  const pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

// zero pads a number to be a 2 digit string
export const paddedNtoS = n => n.toString().padStart(2, "0");

export const getLocalTimeFromDateStr = s => {
  const d = parseISO(s);
  return `${paddedNtoS(d.getUTCHours())}:${paddedNtoS(d.getUTCMinutes())}`;
};
