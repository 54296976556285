import confirmSaga from "../components/Confirm/ConfirmSagas";
import signupSaga from "../components/Signup/SignupSagas";
import loginSaga from "../components/Login/LoginSagas";
import logoutSaga from "../components/Logout/LogoutSagas";
import memoSaga from "../components/MemoTable/MemoTableSagas";
import usersSaga from "../components/Users/UsersSaga";
import postMemoSaga from "../components/MemoForm/MemoFormSagas";
import passwordResetSaga from "../components/PasswordReset/PasswordResetSagas";

/*
 * Collect all sagas from components here: each time a component that has a saga
 * is added, update the array.
 */

export default [
  confirmSaga,
  signupSaga,
  loginSaga,
  logoutSaga,
  memoSaga,
  postMemoSaga,
  usersSaga,
  passwordResetSaga
];
