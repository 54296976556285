import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Divider,
  Checkbox
} from "semantic-ui-react";

const Signup = props => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hideErrors, setHideErrors] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const {
    apiError,
    isFetching,
    isLoggedIn,
    isSignedUp,
    language,
    messages,
    onLanguageChange,
    onSubmit,
    resetForm
  } = props;

  // When input fields are changed, hide errors.
  useEffect(
    () => {
      setHideErrors(true);
    },
    [username, password]
  );

  useEffect(
    () => {
      if (!isSignedUp) resetForm();
      return resetForm;
    },
    [resetForm, isSignedUp]
  );

  useEffect(
    () => {
      onLanguageChange(language);
    },
    [onLanguageChange, language]
  );

  const handleSubmit = e => {
    e.preventDefault();
    setHideErrors(false);
    onSubmit(username, password, language);
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <div className="Signup">
      <style>{`
          body > div,
          body > div > div,
          body > div > div > div.App,
          body > div > div > div.App > div.Signup {
            height: 100%;
          }
        `}</style>
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            {messages.get("Register for a new account")}
          </Header>

          <Form
            size="large"
            onSubmit={handleSubmit}
            error={hideErrors ? null : !!apiError}
            loading={isFetching}
          >
            <input
              id="language"
              name="language"
              type="hidden"
              value={language}
            />
            {isSignedUp && (
              <div>
                <Message positive>
                  <Message.Header>{messages.get("Signed Up!")}</Message.Header>
                  <p>
                    {messages.get(
                      "You will get an e-mail in the next minutes with a verification link."
                    )}{' '}
                    {messages.get(
                      "Add our e-mail address 'noreply@memomolecule.com' to your spam whitelist."
                    )}{' '}
                    {messages.get(
                      "Please check your e-mails and verify your account."
                    )}
                  </p>
                </Message>
              </div>
            )}

            {!hideErrors && apiError && typeof apiError === "string" && (
              <React.Fragment>
                <Message error>{apiError}</Message>
                <p />
              </React.Fragment>
            )}
            {!hideErrors && apiError && typeof apiError === "object" && (
              <React.Fragment>
                {Object.values(apiError).map(err => (
                  <Message key={err} error>
                    {err}
                  </Message>
                ))}
                <p />
              </React.Fragment>
            )}

            <Segment>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder={messages.get("E-mail address")}
                name="username"
                type="text"
                value={username}
                error={!hideErrors ? !!apiError.email : null}
                onChange={e => setUsername(e.target.value)}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                name="password"
                value={password}
                error={!hideErrors ? !!apiError.password : null}
                onChange={e => setPassword(e.target.value)}
              />

              <Button
                color="teal"
                fluid
                size="large"
                disabled={isFetching || !termsAccepted}
              >
                {messages.get("Signup")}
              </Button>

              <Divider />

              <Button as={Link} fluid to="/login">
                {messages.get("Login")}
              </Button>
            </Segment>
          </Form>

          <Segment raised color="red">
            <Checkbox
              label={messages.get(
                "By creating an account, you accept our Terms of Service and Privacy Policy."
              )}
              checked={termsAccepted}
              onChange={(e, data) =>
                setTermsAccepted(data.checked)
              }
            />
          </Segment>
          <Segment>
            <Grid columns={2} relaxed="very" divided>
              <Grid.Column>
                <Link to="/">{messages.get("Back to the app")}</Link>
              </Grid.Column>
              <Grid.Column>
                <div>
                  <a
                    href="privacy-policy.en.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {messages.get("Privacy policy")}
                  </a>
                </div>
                <div>
                  <a
                    href="terms.en.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {messages.get("Terms of Service")}
                  </a>
                </div>
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

Signup.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  apiError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isSignedUp: PropTypes.bool
};

export default Signup;
