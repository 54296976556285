import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";

import {
  Divider,
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment
} from "semantic-ui-react";

const ApiError = ({ apiError, children }) =>
  !apiError ? null : (
    <div className="">
      {typeof apiError === "string" && (
        <React.Fragment>
          <Message error>{apiError}</Message>
          {children}
        </React.Fragment>
      )}
      {typeof apiError === "object" && (
        <React.Fragment>
          {Object.values(apiError).map(err => (
            <Message key={err} error>
              {err}
              <br />
              {children}
            </Message>
          ))}
          <p />
        </React.Fragment>
      )}
    </div>
  );

const Login = props => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hideErrors, setHideErrors] = useState(false);

  const {
    apiError,
    apiErrorPasswordReset,
    authToken,
    isAdmin,
    isFetching,
    isLoggedIn,
    messages,
    onSubmit,
    requestPasswordReset,
    resetErrors,
    resetForm,
    successPasswordReset
  } = props;

  // When input fields are changed, hide errors.
  useEffect(
    () => {
      setHideErrors(true);
    },
    [username, password]
  );

  useEffect(
    () => {
      // on first render, when not logged in, clear state
      if (!authToken) resetForm();
    },
    [resetForm, authToken]
  );

  const handleSubmit = e => {
    e.preventDefault();
    setHideErrors(false);
    resetErrors();
    onSubmit(username, password);
  };

  return isLoggedIn ? (
    isAdmin ? (
      <Redirect to="/admin" />
    ) : (
      <Redirect to="/" />
    )
  ) : (
    <div className="Login">
      {/*
          All the elements up to the `Grid`
          below must have a height of 100%.
          This style must not be in .css file because that will clash with
          full page modal.
        */}
      <style>{`
          body > div,
          body > div > div,
          body > div > div > div.App,
          body > div > div > div.App > div.Login {
            height: 100%;
          }
        `}</style>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        style={{ height: "100%" }}
      >
        <Grid.Row>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="teal" textAlign="center" />

            <ApiError apiError={hideErrors ? null : apiError}>
              <p>
                {messages.get(
                  "If you have recently signed up, have you verified your email?"
                )}
              </p>
            </ApiError>

            <ApiError apiError={apiErrorPasswordReset} />

            {(apiError || apiErrorPasswordReset) && username && (
              <React.Fragment>
                <Divider hidden />
                <Button onClick={() => requestPasswordReset(username)}>
                  {messages.get("Reset Password")}
                </Button>
              </React.Fragment>
            )}

            {successPasswordReset === true && (
              <React.Fragment>
                <Divider hidden />
                <Message info>
                  {messages.get("Check your e-mails for a password reset link")}
                </Message>
              </React.Fragment>
            )}

            <Divider hidden />

            <Form
              size="large"
              onSubmit={handleSubmit}
              error={!!apiError}
              loading={isFetching}
            >
              <Segment>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder={messages.get("E-mail address")}
                  name="username"
                  type="text"
                  value={username}
                  error={!!apiError.email}
                  onChange={e => setUsername(e.target.value)}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />

                <Button color="teal" fluid size="large" disabled={isFetching}>
                  {messages.get("Login")}
                </Button>

                <Divider />

                <Button as={Link} fluid to="/signup">
                  {messages.get("Signup")}
                </Button>
              </Segment>
            </Form>
            <Segment>
              <Grid columns={2} relaxed="very" divided>
                <Grid.Column>
                  <Link to="/">{messages.get("Back to the app")}</Link>
                </Grid.Column>
                <Grid.Column>
                  <div>
                    <a
                      href="privacy-policy.en.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {messages.get("Privacy policy")}
                    </a>
                  </div>
                  <div>
                    <a
                      href="terms.en.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {messages.get("Terms of Service")}
                    </a>
                  </div>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  apiError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  apiErrorPasswordReset: PropTypes.string
};

export default Login;
