import log from "loglevel";
import { put, takeEvery, call } from "redux-saga/effects";

import { RESET_PASSWORD_RESET_DATA } from 'components/PasswordReset/PasswordResetDuck';

import { BROWSER_TIMEZONE } from '../../uistate';
import {
  FETCHING_SIGNUP,
  FETCHING_SIGNUP_SUCCESS,
  FETCHING_SIGNUP_FAILURE,
  CLEAR_SIGNUP_DATA,
  REQUEST_VERIFICATION,
  REQUEST_VERIFICATION_SUCCESS,
  REQUEST_VERIFICATION_FAILURE
} from "./SignupDuck";
import fetchApi from "../../helpers/fetchApi";

/*
 * Sagas to handle calls to backend API
 */

export function* fetchSignupSaga(action) {
  log.debug("fetchSignupSaga called", action);
  try {
    const username = action.username.trim();
    const password = action.password.trim();
    const language = action.language.trim();
    const timeZone = BROWSER_TIMEZONE;
    const url = "/persons";
    const payload = { email: username, password, language, timeZone };

    // call API
    const jsonData = yield call(fetchApi, url, { payload });
    if (!jsonData.error) {
      yield put({ type: FETCHING_SIGNUP_SUCCESS, userObj: jsonData });
      yield put({ type: CLEAR_SIGNUP_DATA });
    } else {
      yield put({
        type: FETCHING_SIGNUP_FAILURE,
        apiError: jsonData.error.text
      });
    }
  } catch (error) {
    log.error("FETCHING_SIGNUP_FAILURE", error.message);
  }
}

export function* requestVerificationLinkSaga(action) {
  log.debug("requestVerificationLinkSaga called", { action });
  try {
    const id = action.id;
    const url = `/persons/${id}/verify`;

    yield put({ type: RESET_PASSWORD_RESET_DATA });

    // call API
    const jsonData = yield call(fetchApi, url, {});
    if (!jsonData.error) {
      yield put({ type: REQUEST_VERIFICATION_SUCCESS, userObj: jsonData });
    } else {
      yield put({
        type: REQUEST_VERIFICATION_FAILURE,
        apiError: jsonData.error.text._error ? jsonData.error.text._error : jsonData.error.text
      });
    }
  } catch (error) {
    log.error("REQUEST_VERIFICATION_FAILURE", error.message);
  }
}

// listen for actions of type FETCHING_SIGNUP and use them
export default function* signupSaga() {
  yield takeEvery(FETCHING_SIGNUP, fetchSignupSaga);
  yield takeEvery(REQUEST_VERIFICATION, requestVerificationLinkSaga);
}
