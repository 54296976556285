import immutable from "object-path-immutable";

export const FETCH_USERS_SUBMIT = "FETCH_USERS_SUBMIT";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const FETCH_USER_DETAILS_SUBMIT = "FETCH_USER_DETAILS_SUBMIT";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";
export const CHANGE_USER_DETAILS_SUBMIT = "CHANGE_USER_DETAILS_SUBMIT";
export const CHANGE_USER_DETAILS_SUCCESS = "CHANGE_USER_DETAILS_SUCCESS";
export const CHANGE_USER_DETAILS_FAILURE = "CHANGE_USER_DETAILS_FAILURE";
export const DELETE_USER_SUBMIT = "DELETE_USER_SUBMIT";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const loadUsers = () => ({
  type: FETCH_USERS_SUBMIT
});

export const changeUserDetails = user => ({
  type: CHANGE_USER_DETAILS_SUBMIT,
  user
});

export const markUserDeleted = user => ({
  type: DELETE_USER_SUBMIT,
  user
});


const initialState = {
  data: [],
  meta: {}
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUBMIT:
      return immutable(state)
        .set("meta.apiError", "")
        .set("meta.isFetching", true)
        .value();

    case FETCH_USERS_SUCCESS:
      return immutable(state)
        .set("data", action.users)
        .set("meta.isFetching", false)
        .value();

    case FETCH_USERS_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("meta.apiError", action.apiError)
        .value();

    case DELETE_USER_SUBMIT:
    case CHANGE_USER_DETAILS_SUBMIT:
      return immutable(state)
        .set("meta.apiError", "")
        .set("meta.isFetching", true)
        .value();

    case DELETE_USER_SUCCESS:
    case CHANGE_USER_DETAILS_SUCCESS:
      return immutable(state)
        .set(
          `data.${state.data.findIndex(u => u.id === action.user.id)}`,
          action.user
        )
        .set("meta.isFetching", false)
        .value();

    case DELETE_USER_FAILURE:
    case CHANGE_USER_DETAILS_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("meta.apiError", action.apiError)
        .value();

    default:
      return state;
  }
};

export default users;
