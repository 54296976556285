import { connect } from "react-redux";

import { getMessages } from "../../helpers/helpers";
import { resetForm, resetErrors, login } from "./LoginDuck.js";
import {
  resetData as clearPasswordResetData,
  requestPasswordReset
} from "components/PasswordReset/PasswordResetDuck";
import Login from "./Login";
import { createSelector } from "reselect";

const selectLogin = state => state.login;

const getIsAdmin = createSelector(
  selectLogin,
  login => ((login || {}).roles || []).includes("admin")
);

/*
 * Redux container for Login
 */

const mapStateToProps = state => {
  const { login, passwordReset } = state;
  return {
    isFetching: login.isFetching,
    isLoggedIn: !!login.authToken,
    isAdmin: getIsAdmin(state),
    authToken: login.authToken,
    apiError: login.apiError ? login.apiError : "",
    apiErrorPasswordReset: passwordReset.apiError || "",
    successPasswordReset: passwordReset.success,
    messages: getMessages(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetForm: () => dispatch(resetForm()),
    resetErrors: () => dispatch(resetErrors()),
    onSubmit: (username, password) => dispatch(login(username, password)),
    requestPasswordReset: email => dispatch(requestPasswordReset(email)),
    clearPasswordResetData: () => dispatch(clearPasswordResetData())
  };
};

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default LoginContainer;
