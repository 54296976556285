import parseISO from "date-fns/parseISO";
import addMinutes from "date-fns/addMinutes";
import immutable from "object-path-immutable";
import {
  POST_MEMO_SUCCESS,
  DELETE_MEMO_SUCCESS
} from "components/MemoForm/MemoFormDuck";
import { getLocalTimeFromDateStr } from "helpers/helpers";

export const FETCHING_MEMOS = "FETCHING_MEMOS";
export const FETCHING_MEMOS_SUCCESS = "FETCHING_MEMOS_SUCCESS";
export const FETCHING_MEMOS_FAILURE = "FETCHING_MEMOS_FAILURE";
export const SET_CURRENT_MEMO = "SET_CURRENT_MEMO";
export const SET_SELECTED_MEMO_ID = "SET_SELECTED_MEMO_ID";

/*
 * This file contains the action creators for Memos
 */

export const setCurrentMemo = memoId => ({
  type: SET_CURRENT_MEMO,
  memoId
});

export const loadMemos = () => ({
  type: FETCHING_MEMOS
});

// Manipulate incoming memo to have Date object, separate time field and
// whether it has an overridden timeZone
const augmentedMemo = m => ({
  ...m,
  when: addMinutes(parseISO(m.when), new Date().getTimezoneOffset()),
  time: getLocalTimeFromDateStr(m.when),
  usesTimezone: !!m.timeZone
});

const initialState = {
  memoArr: [],
  isFetching: false,
  isDataFetched: false,
  apiError: ""
};

const memosReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_MEMO:
    case SET_SELECTED_MEMO_ID:
      return {
        ...state,
        currentMemo: action.memoId
      };

    case FETCHING_MEMOS:
      return {
        ...state,
        isFetching: true,
        apiError: ""
      };

    case FETCHING_MEMOS_SUCCESS:
      const { memos } = action;
      return {
        ...state,
        isFetching: false,
        apiError: "",
        memoArr: memos.map(m => augmentedMemo(m)),
        isDataFetched: true
      };

    case FETCHING_MEMOS_FAILURE:
      return {
        ...state,
        isFetching: false,
        apiError: action.apiError
      };

    case POST_MEMO_SUCCESS:
      const memo = augmentedMemo(action.memo);
      return action.create
        ? immutable(state)
            .push("memoArr", memo)
            .set("apiError", "")
            .value()
        : immutable(state)
            .set(
              `memoArr.${state.memoArr.findIndex(m => m.id === memo.id)}`,
              memo
            )
            .set("apiError", "")
            .value();

    case DELETE_MEMO_SUCCESS:
      return immutable(state)
        .set("apiError", "")
        .del(`memoArr.${state.memoArr.findIndex(m => m.id === action.id)}`)
        .push("lastDeleted", action.memo)
        .value();

    default:
      return state;
  }
};

export default memosReducer;
