
//TODO: handle start of week SU vs MO
export const DOWMAP = {
  0: "SU",
  1: "MO",
  2: "TU",
  3: "WE",
  4: "TH",
  5: "FR",
  6: "SA",
  7: "SU"
};

export const DOWMAP_REV = {
  MO: 1,
  TU: 2,
  WE: 3,
  TH: 4,
  FR: 5,
  SA: 6,
  SU: 7
};

export const sortDaysOfWeek = (a, b) => DOWMAP_REV[a] - DOWMAP_REV[b];

const WKDAYS = {
  MO: "Monday",
  TU: "Tuesday",
  WE: "Wednesday",
  TH: "Thursday",
  FR: "Friday",
  SA: "Saturday",
  SU: "Sunday"
};

export const wkdays = WKDAYS;

export const RT_DAILY = "day";
export const RT_WEEKLY = "week";
export const RT_MONTHLY = "month";
export const RT_YEARLY = "year";
export const RT_DEFAULT = RT_WEEKLY;

// recurrence types/frequencies
export const RTYPES = {
  day: "daily",
  week: "weekly",
  month: "monthly",
  year: "yearly"
};
export const rtypes = RTYPES;

// recurrence type 'monthly': sub options
// certain day of month (set by 'when' field)
export const MNTH_DAY = "MNTH_DAY";
// certain day of week in month (e.g. 3rd Friday)
export const MNTH_DOW = "MNTH_DOW";
// first day of month
export const MNTH_FIRSTDAY = "MNTH_FIRSTDAY";
// last day of month
export const MNTH_LASTDAY = "MNTH_LASTDAY";
// certain last day of week in month (e.g. last Friday)
export const MNTH_LASTDOW = "MNTH_LASTDOW";
// default value if none was set by user
export const MNTH_DEFAULT = MNTH_DAY;

// recurrence 'base' time unit
export const RTIMEUNIT = {
  day: "day",
  week: "week",
  month: "month",
  year: "year"
};

// prenotification time units
export const PR_MINUTES = "MINUTES";
export const PR_HOURS = "HOURS";
export const PR_DAYS = "DAYS";
