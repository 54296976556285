import React from "react";
import { Divider, Grid, Header, Button, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";

class Verified extends React.Component {
  state = { email: "" };
  handleEmailChange = e => {
    const email = e.target.value;
    this.setState({ email });
    console.log(email);
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.props.uid);
  };
  render() {
    const { messages, apiError, isFetching } = this.props;
    return (
      <Grid container textAlign="center" verticalAlign="middle">
        <Grid.Row style={{ height: "80vh" }}>
          <Grid.Column mobile={16} tablet={12} computer={8}>
            {apiError && (
              <Message warning>
                <Message.Header>{apiError}</Message.Header>
              </Message>
            )}
            <Header as="h2" color="teal" textAlign="center">
              {messages.get("Request a new verification email")}
            </Header>
            <Button
              onClick={this.handleSubmit}
              disabled={isFetching}
              color="teal"
            >
              {messages.get("Send")}
            </Button>
            <Divider />
            <Link to="/login">
              <Button>{messages.get("Login")}</Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Verified;
