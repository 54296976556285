import log from "loglevel";

import {
  SET_CURRENT_MEMO,
  FETCHING_MEMOS,
  FETCHING_MEMOS_SUCCESS,
  FETCHING_MEMOS_FAILURE
} from "./MemoTableDuck";
import { SET_MEMO, CREATE_NEW_MEMO } from "../MemoForm/MemoFormDuck";
import { put, takeEvery, call, select } from "redux-saga/effects";
import fetchApi from "../../helpers/fetchApi";
import Messages from "messageformat/messages";
import msgData from "lang/messages";
import { toast } from "react-toastify";

/*
 * Sagas to handle calls to backend API
 */

export function* fetchMemosSaga(action) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const userId = yield select(state => state.login.userId);
    const url = `/persons/${userId}/memos`;

    // call API
    const jsonData = yield call(fetchApi, url, { method: "GET", authToken });

    // ... and update Redux store
    if (!jsonData.error) {
      yield put({ type: FETCHING_MEMOS_SUCCESS, memos: jsonData });
      const language = yield select(state => state.uistate.language);
      const i18n = new Messages(msgData, language);
      toast.success(i18n.get("Fetched all memos"));
    } else {
      yield put({
        type: FETCHING_MEMOS_FAILURE,
        apiError: jsonData.error.text
      });
    }
  } catch (error) {
    log.error("FETCHING_MEMOS_FAILURE", error);
  }
}

export function* setMemoInFormSaga(action) {
  const currentMemo = yield select(state => state.memos.currentMemo);
  if (currentMemo) {
    const allMemos = yield select(state => state.memos.memoArr);
    const memo = currentMemo && allMemos.find(m => m.id === currentMemo);
    yield put({ type: SET_MEMO, memo });
  }
}

export function* unsetSelectedMemoSaga(action) {
  yield put({ type: SET_CURRENT_MEMO, currentMemo: undefined });
}

// listen for actions of type FETCHING_MEMOS and use them
export default function* memosSaga() {
  yield takeEvery(FETCHING_MEMOS, fetchMemosSaga);
  yield takeEvery(SET_CURRENT_MEMO, setMemoInFormSaga);
  yield takeEvery(CREATE_NEW_MEMO, unsetSelectedMemoSaga);
}
