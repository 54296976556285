import log from "loglevel";
import { call, put, takeEvery } from "redux-saga/effects";

import {
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUBMIT,
  RESET_PASSWORD_SUCCESS,
  REQUEST_PASSWORD_FAILURE,
  REQUEST_PASSWORD_SUBMIT,
  REQUEST_PASSWORD_SUCCESS
} from "./PasswordResetDuck";
import fetchApi from "helpers/fetchApi";

export function* fetchResetPassSaga(action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { newPassword, access_token } = action;
    const url = "/persons/reset-password";
    const payload = { newPassword };

    const data = yield call(fetchApi, url, { payload, authToken: access_token });

    if (!data.error) {
      yield put({ type: RESET_PASSWORD_SUCCESS });
    } else {
      yield put({
        type: RESET_PASSWORD_FAILURE,
        apiError: data.error.text._error
          ? data.error.text._error
          : data.error.text
      });
    }
  } catch (error) {
    log.error("fetchResetPassSaga error", error);
    yield put({
      type: RESET_PASSWORD_FAILURE,
      apiError: "Network error."
    });
  }
}

export function* fetchRequestPassSaga(action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { email } = action;
    const url = "/persons/reset";
    const payload = { email };

    const data = yield call(fetchApi, url, { payload });

    if (!data.error) {
      yield put({ type: REQUEST_PASSWORD_SUCCESS });
    } else {
      yield put({
        type: REQUEST_PASSWORD_FAILURE,
        apiError: data.error.text._error
          ? data.error.text._error
          : data.error.text
      });
    }
  } catch (error) {
    log.error("fetchRequestPassSaga error", error);
    yield put({
      type: REQUEST_PASSWORD_FAILURE,
      apiError: "Network error."
    });
  }
}


// listen for actions of type RESET_PASSWORD_SUBMIT and use them
export default function* resetPassSaga() {
  yield takeEvery(RESET_PASSWORD_SUBMIT, fetchResetPassSaga);
  yield takeEvery(REQUEST_PASSWORD_SUBMIT, fetchRequestPassSaga);
}
