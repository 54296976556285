import log from "loglevel";

import {
  SEND_CONFIRM_SUBMIT,
  SEND_CONFIRM_SUCCESS,
  SEND_CONFIRM_FAILURE
} from "./ConfirmDuck";
import { put, takeEvery, call } from "redux-saga/effects";
import fetchApi from "../../helpers/fetchApi";

export function* fetchConfirmSaga(action) {
  log.debug("fetchConfirmSaga called", { action });
  try {
    const { uid, token } = action;
    const url = '/persons/confirm';
    const query = {
      uid,
      token
    };

    // call API
    const jsonData = yield call(fetchApi, url, { method: "GET", query });
    if (!jsonData.error) {
      yield put({
        type: SEND_CONFIRM_SUCCESS,
        userObj: jsonData
      });
    } else {
      yield put({
        type: SEND_CONFIRM_FAILURE,
        apiError: jsonData.error.text._error ? jsonData.error.text._error : jsonData.error.text
      });
    }
  } catch (error) {
    log.error("SEND_CONFIRM_FAILURE", error.message);
  }
}

// listen for actions of type FETCHING_CONFIRM and use them
export default function* confirmSaga() {
  yield takeEvery(SEND_CONFIRM_SUBMIT, fetchConfirmSaga);
}
