import log from "loglevel";
import { showToast } from "../../helpers/helpers";
import { SET_LANGUAGE, CHANGE_LANGUAGE, CHANGE_TIMEZONE, CHANGE_ALLPAUSED, CHANGE_HIDEHINTS } from "uistate";

import {
  FETCHING_LOGIN,
  FETCHING_LOGIN_SUCCESS,
  FETCHING_LOGIN_FAILURE,
  FETCHING_PERSON_SUCCESS,
  FETCHING_PERSON_FAILURE,
  SET_PERSON_SETTINGS_SUBMIT,
  SET_PERSON_SETTINGS_SUCCESS,
  SET_PERSON_SETTINGS_FAILURE
} from "./LoginDuck";
import { RESET_PASSWORD_RESET_DATA } from "components/PasswordReset/PasswordResetDuck";
import { FETCHING_MEMOS } from "components/MemoTable/MemoTableDuck";
import { put, takeEvery, call, select } from "redux-saga/effects";
import fetchApi from "../../helpers/fetchApi";

/*
 * Sagas to handle calls to backend API
 */

export function* fetchLoginSaga({username, password}) {
  try {
    const url = "/persons/login";
    const payload = { email: username, password: password };

    yield put({ type: RESET_PASSWORD_RESET_DATA });

    // call API
    const jsonData = yield call(fetchApi, url, { payload });
    if (!jsonData.error) {

      const personData = yield call(fetchPersonSaga, {userObj: jsonData});
      if (personData) {
      yield put({ type: FETCHING_PERSON_SUCCESS, data: personData });
      if (personData.settings)
        yield put({ type: SET_LANGUAGE, language: personData.settings.language });
      }

      yield put({ type: FETCHING_LOGIN_SUCCESS, userObj: jsonData });
    } else {
      yield put({
        type: FETCHING_LOGIN_FAILURE,
        apiError: jsonData.error.text._error
          ? jsonData.error.text._error
          : jsonData.error.text
      });
    }
  } catch (error) {
    log.error("FETCHING_LOGIN_FAILURE", error.message);
  }
}

export function* fetchPersonSaga({ userObj }) {
  try {
    const url = `/persons/${userObj.userId}`;
    const authToken = userObj.id;
    const query = {
      filter: JSON.stringify({
        include: ["settings"]
      })
    };

    // call API
    const jsonData = yield call(fetchApi, url, {
      method: "GET",
      authToken,
      query
    });
    if (!jsonData.error) {
      return jsonData;
    } else {
      yield put({
        type: FETCHING_PERSON_FAILURE,
        apiError: jsonData.error.text._error
          ? jsonData.error.text._error
          : jsonData.error.text
      });
    }
  } catch (error) {
    log.error("FETCHING_PERSON_FAILURE", error.message);
  }
}

export function* setPersonLanguage(action) {
  // try to store changed language but only if it should, i.e. user is signed in
  if(action.updateUserSettings)
    yield call(patchPersonSettings, { language: action.language });
}

export function* setPersonTimezone(action) {
  const status = yield call(patchPersonSettings, { timeZone: action.timeZone });

  // Changing the timezone will probably mean a complete recalculation of Memo
  // dates, therefore must reload them all.
  if(status)
    yield put({ type: FETCHING_MEMOS });
}

export function* setAllPaused(action) {
  yield call(patchPersonSettings, { allPaused: action.allPaused });
}

export function* setHideHints(action) {
  yield call(patchPersonSettings, { hideHints: action.hideHints });
}

//  As a background task, acts on changes to user settings like
//  time zone and language without explicitly having to be called; it
//  takes the data from the Redux store. In this way, single field
//  values can be updated without the caller having to know all Settings
//  values.
export function* patchPersonSettings(action) {
  try {
    const userId = yield select(state => state.login.userId);
    const authToken = yield select(state => state.login.authToken);
    let updateSettings = true;
    let settings = yield select(state => state.login.settings);
    if (!settings) {
      updateSettings = false;
      settings = {};
    }
    const url = `/persons/${userId}/settings`;
    const payload = {
      ...settings,
      ...action
    };

    const jsonData = yield call(fetchApi, url, {
      payload,
      method: updateSettings ? "PUT" : "POST",
      authToken
    });

    if (!jsonData.error) {
      yield put({
        type: SET_PERSON_SETTINGS_SUCCESS,
        settings: jsonData,
        success: true
      });
      yield call(showToast, `Settings updated`);
      return true;
    } else {
      const apiError = jsonData.error.text._error
        ? jsonData.error.text._error
        : jsonData.error.text;
      yield put({
        type: SET_PERSON_SETTINGS_FAILURE,
        apiError
      });
      yield call(
        showToast,
        `Updating settings failed: ${apiError}`,
        {},
        "App__toast--failure"
      );
    }
  } catch (err) {
    log.warn("setPersonSettings error", err);
    yield call(
      showToast,
      `Updating settings network failure: ${err}`,
      {},
      "App__toast--failure"
    );

    yield put({
      type: SET_PERSON_SETTINGS_FAILURE,
      apiError: "Network error"
    });
  }
}

export default function* loginSaga() {
  yield takeEvery(FETCHING_LOGIN, fetchLoginSaga);
  yield takeEvery(CHANGE_LANGUAGE, setPersonLanguage);
  yield takeEvery(CHANGE_TIMEZONE, setPersonTimezone);
  yield takeEvery(CHANGE_ALLPAUSED, setAllPaused);
  yield takeEvery(CHANGE_HIDEHINTS, setHideHints);
  yield takeEvery(SET_PERSON_SETTINGS_SUBMIT, patchPersonSettings);
}
