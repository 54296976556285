import React from "react";
import PropTypes from "prop-types";
import { Flag, Form } from "semantic-ui-react";

export const LANG_EN = "en";
export const LANG_EN_GB = "en-GB";
export const LANG_EN_US = "en-US";
export const LANG_DE = "de";
export const LANG_DE_DE = "de-DE";
export const LANG_DE_AT = "de-AT";
export const LANG_DE_CH = "de-CH";
export const LANG_DE_LI = "de-LI";
export const LANG_DE_LU = "de-LU";
export const LANG_GD = "gd";
export const LANG_FR = "fr";
export const LANG_FR_BE = "fr-BE"; // French (Belgium)
export const LANG_FR_CA = "fr-CA"; // French (Canada)
export const LANG_FR_CH = "fr-CH"; // French (Switzerland)
export const LANG_FR_FR = "fr-FR"; // French (France)
export const LANG_FR_LU = "fr-LU"; // French (Luxembourg)
export const LANG_FR_MC = "fr-MC"; // French (Principality of Monaco)

export const LANG_DEFAULT = LANG_EN_GB;

const options = [
  { key: LANG_DE, text: "Deutsch", flag: "de", value: LANG_DE },
  { key: LANG_EN_GB, text: "English (GB)", flag: "gb", value: LANG_EN_GB },
  { key: LANG_EN_US, text: "English (US)", flag: "us", value: LANG_EN_US },
  { key: LANG_FR, text: "Français", flag: "fr", value: LANG_FR },
  { key: LANG_GD, text: "Gàidhlig", flag: "gb sct", value: LANG_GD }
];

export const supportedLanguages = lang => {
  switch (lang) {
    case LANG_EN:
    case LANG_EN_GB:
    case LANG_EN_US:
      return lang;

    case LANG_DE:
    case LANG_DE_DE:
    case LANG_DE_AT:
    case LANG_DE_CH:
      return LANG_DE;

    case LANG_GD:
      return lang;

    case LANG_FR:
    case LANG_FR_BE:
    case LANG_FR_CA:
    case LANG_FR_CH:
    case LANG_FR_FR:
    case LANG_FR_LU:
    case LANG_FR_MC:
      return lang;

    default:
      return LANG_DEFAULT;
  }
};

const trigger = language => (
  <span>
    <Flag name={(options.find(o => o.value === language) || {}).flag} />{" "}
    {(options.find(o => o.value === language) || {}).text}
  </span>
);

const LanguageChooser = ({ language = LANG_DEFAULT, onChange }) => (
  <Form.Dropdown
    trigger={trigger(language)}
    options={options}
    value={language}
    onChange={(e, { value }) => onChange(value)}
  />
);

LanguageChooser.propTypes = {
  language: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default LanguageChooser;
