import { connect } from "react-redux";

import { parseQuery } from '../../helpers/helpers';
import { sendConfirm } from "./ConfirmDuck";
import Confirm from "./Confirm";
import { getMessages } from "../../helpers/helpers";

const mapStateToProps = (state, {location}) => {
  const query = location ? parseQuery(location.search) : {};
  const uid = query["uid"];
  const token = query["token"];
  return {
    apiError: state.emailConfirm.apiError,
    success: state.emailConfirm.success,
    uid,
    token,
    messages: getMessages(state),
  };
};

const mapDispatchToProps = dispatch => ({
  sendConfirm: (uid, token) => dispatch(sendConfirm(uid, token))
});

const ConfirmContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirm);
export default ConfirmContainer;
