import immutable from "object-path-immutable";
import {
  SET_LANGUAGE,
  CHANGE_LANGUAGE,
  CHANGE_TIMEZONE,
  SHOW_SETTINGS
} from "uistate";

export const FETCHING_LOGIN = "FETCHING_LOGIN";
export const FETCHING_LOGIN_SUCCESS = "FETCHING_LOGIN_SUCCESS";
export const FETCHING_LOGIN_FAILURE = "FETCHING_LOGIN_FAILURE";
export const FETCHING_PERSON_SUBMIT = "FETCHING_PERSON_SUBMIT";
export const FETCHING_PERSON_SUCCESS = "FETCHING_PERSON_SUCCESS";
export const FETCHING_PERSON_FAILURE = "FETCHING_PERSON_FAILURE";
export const CLEAR_LOGIN_DATA = "CLEAR_LOGIN_DATA";
export const CLEAR_LOGIN_ERRORS = "CLEAR_LOGIN_ERRORS";

export const SET_PERSON_SETTINGS_SUBMIT = "SET_PERSON_SETTINGS_SUBMIT";
export const SET_PERSON_SETTINGS_SUCCESS = "SET_PERSON_SETTINGS_SUCCESS";
export const SET_PERSON_SETTINGS_FAILURE = "SET_PERSON_SETTINGS_FAILURE";

/*
 * This file contains the action creators for Login
 */

export const resetForm = () => {
  return { type: CLEAR_LOGIN_DATA };
};

export const resetErrors = () => {
  return { type: CLEAR_LOGIN_ERRORS };
};

export const login = (username, password) => {
  return {
    type: FETCHING_LOGIN,
    username,
    password
  };
};

const initialState = {
  username: "",
  password: "",
  userId: -1,
  ttl: 0,
  isFetching: false,
  authToken: "",
  apiError: undefined
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_LOGIN_DATA:
      return initialState;

    case CLEAR_LOGIN_ERRORS:
      return immutable.set(state, "apiError", undefined);

    case FETCHING_LOGIN:
      return immutable.set(state, "isFetching", true);

    case FETCHING_LOGIN_SUCCESS:
      const { userObj } = action;
      return immutable(state)
        .set("password", undefined)
        .set("isFetching", false)
        .set("userId", userObj.userId)
        .set("authToken", userObj.id)
        .set("ttl", userObj.ttl)
        .value();

    case FETCHING_LOGIN_FAILURE:
      return immutable(state)
        .set("isFetching", false)
        .set("userId", -1)
        .set("authToken", "")
        .set("ttl", 0)
        .set("apiError", action.apiError)
        .value();

    case FETCHING_PERSON_SUCCESS:
      return immutable(state)
        .set("settings", action.data.settings)
        .set("username", action.data.email)
        .set("roles", action.data.roles)
        .set("apiError", undefined)
        .value();

    case FETCHING_PERSON_FAILURE:
      return immutable.set(state, "apiError", action.apiError);

    case CHANGE_LANGUAGE:
    case CHANGE_TIMEZONE:
    case SET_PERSON_SETTINGS_SUBMIT:
      return immutable(state)
        .set("isFetching", true)
        .set("apiErrorSettings", undefined)
        .value();

    case SET_PERSON_SETTINGS_SUCCESS:
      return immutable(state)
        .set("isFetching", false)
        .set("apiErrorSettings", undefined)
        .set("settings", action.settings)
        .value();

    case SET_PERSON_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        apiErrorSettings: action.apiError
      };

    case SHOW_SETTINGS:
      return action.open
        ? state
        : {
            ...state,
            apiErrorSettings: undefined
          };

    case SET_LANGUAGE:
      return immutable.set(state, "settings.language", action.language);

    default:
      return state;
  }
};

export default loginReducer;
