import log from 'loglevel';

import { 
  FETCHING_LOGOUT, 
  FETCHING_LOGOUT_SUCCESS, 
  FETCHING_LOGOUT_FAILURE 
} from './LogoutDuck'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import fetchApi from '../../helpers/fetchApi'
import { toast } from "react-toastify";

/* 
 * Sagas to handle calls to backend API
 */

export function* fetchLogoutSaga (action) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const url = `/persons/logout`;

    toast.dismiss();

    // call API
    const jsonResult = yield call(fetchApi, url, { authToken })
    !jsonResult.error ?(
      yield put({ type: FETCHING_LOGOUT_SUCCESS })
    ):(
      yield put({ type: FETCHING_LOGOUT_FAILURE, message: jsonResult.error.text })
    )
  } catch (error) {
    log.error('FETCHING_LOGOUT_FAILURE', error)
  }
}

// listen for actions of type FETCHING_LOGOUT and use them
export default function* logoutSaga () {
  yield takeEvery(FETCHING_LOGOUT, fetchLogoutSaga)
}

