import { connect } from "react-redux";

import { getMessages, parseQuery } from "../../helpers/helpers";
import {
  setNewPassword,
  submitNewPassword,
  resetData
} from "./PasswordResetDuck";
import PasswordReset from "./PasswordReset";

const mapStateToProps = (state, { location }) => {
  const query = location ? parseQuery(location.search) : {};
  const access_token = query["access_token"];
  return {
    messages: getMessages(state),
    success: state.passwordReset.success,
    apiError: state.passwordReset.apiError,
    isFetching: state.passwordReset.isFetching,
    newPassword: state.passwordReset.newPassword,
    access_token
  };
};

const mapDispatchToProps = dispatch => ({
  setPassword: password => dispatch(setNewPassword(password)),
  handleSubmit: (password, access_token) =>
    dispatch(submitNewPassword(password, access_token)),
  resetData: () => dispatch(resetData())
});

const PasswordResetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset);
export default PasswordResetContainer;
